/* eslint-disable max-len */
interface IQuotes {
  text: string;
  logo: string;
}

const QUOTES_LIST: Array<IQuotes> = [
  {
    text: "Bowen Zhou, CEO of Moneta Digital, introduced in mid-June the MMXN token “the first Mexican stablecoin“ with a conversion rate that promises to always be 1 MMXN to 1 Mexican peso.",
    logo: `${require("@Assets/images/quotes/en/0.png")}`,
  },
  {
    text: "“Can one protect oneself from fraud in decentralized finance without being a computer security specialist? Yes, and it is not as difficult as you might imagine. You just have to open your eyes wide and follow these steps recommended by José Guadiana, VP of operations at Moneta.”",
    logo: `${require("@Assets/images/quotes/en/1.png")}`,
  },
  {
    text: "“The benefits of stablecoins include payments with lower costs involved, more secure, real-time and more competitive compared to what consumers and businesses use today.”",
    logo: `${require("@Assets/images/quotes/en/2.png")}`,
  },
  {
    text: "“A stablecoin pegged to the value of the Mexican peso, which aims to eliminate the need for Mexican crypto-investors to have to be doing so many conversions and they can think in their own currency.”",
    logo: `${require("@Assets/images/quotes/en/3.png")}`,
  },
  {
    text: "“MMXN has developed fiat-crypto plans as a medium of exchange for financial products, remittances, payments and store of value in order to increase the speed of transactions and decrease the cost of transferring value in a borderless way.”",
    logo: `${require("@Assets/images/quotes/en/4.png")}`,
  },
];

export default QUOTES_LIST;
