/* eslint-disable react/no-unescaped-entities */
import { useRef, useState, useEffect } from "react";
import RootLayout from "@Layout/RootLayout";
import styled from "styled-components";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  > .top {
    background-image: url(${require("@Assets/images/home/topBg.png")});
    background-position: right bottom;
    background-repeat: no-repeat;
    padding: 0 120px;
    > .topInner {
      margin: 0 auto;
      width: 100%;
      max-width: 1400px;
      min-width: 1200px;
      height: 540px;
      > .title {
        font-size: 66px;
        font-family: CASaygonText-Bold, CASaygonText;
        color: #00ec7d;
        line-height: 79px;
      }
      > img {
        width: 525px;
      }
    }
  }
  > .bottom {
    width: 100%;
    padding: 82px 120px 60px;
    background: #fff;
    min-width: 100%;
    width: fit-content;
    > .content {
      max-width: 1400px;
      min-width: 1200px;
      margin: 0 auto;
      > :first-child {
        position: relative;
        > p {
          margin-right: 80px;
          font-size: 20px;
          font-family: CASaygonText-Regular, CASaygonText;
          font-weight: 400;
          color: #727272;
          line-height: 24px;
          cursor: pointer;
          padding-bottom: 20px;
          transition: color 0.4s ease-in;
          &.active {
            color: #080f2a;
          }
        }
        > div {
          position: absolute;
          width: 0;
          height: 3px;
          background: rgba(8, 15, 42, 1);
          bottom: 0;
          transition: all 0.3s ease-in;
        }
      }
      > .termContent {
        font-size: 16px;
        font-family: CASaygonText-Regular, CASaygonText;
        font-weight: 400;
        color: #555555;
        line-height: 24px;
        > div {
          padding-bottom: 20px;
          text-align: center;
          font-weight: bold;
          font-size: 22px;
          line-height: 40px;
        }
        > p {
          padding-bottom: 20px;
        }
        a {
          color: #08a65c;
          text-decoration: underline;
        }
      }
    }
  }
`;

function LegalPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams<{ type: "privacy" | "terms" }>();
  const type = params.type === "terms" ? "terms" : "privacy";
  const [choose, setChoose] = useState<number>(type === "terms" ? 1 : 0);
  const [selectWidth, setSelectWidth] = useState<[]>([]);
  const [selectMove, setSelectMove] = useState<[]>([]);
  const selectEl = useRef(null as null | HTMLDivElement);

  useEffect(() => {
    setTimeout(() => {
      const el: any = selectEl.current?.getElementsByTagName("p");
      const width: any = [];
      const move: any = [];
      const list = Array.from(el);
      list.forEach((element: any) => {
        width.push(element.offsetWidth);
        move.push(element.offsetLeft);
      });
      setSelectWidth(width);
      setSelectMove(move);
    }, 800);
  }, []);

  return (
    <RootLayout>
      <Wrapper>
        <div className="top">
          <div className="topInner row-between">
            <h2 className="title">LEGAL</h2>
            <img src={require("@Assets/images/faq/libra.png")} alt="banner" />
          </div>
        </div>
        <div className="bottom">
          <div className="content">
            <div className="row-start" ref={selectEl}>
              <p
                className={choose === 0 ? "active" : ""}
                onMouseEnter={() => {
                  setChoose(0);
                }}
                onMouseLeave={() => {
                  setChoose(type === "terms" ? 1 : 0);
                }}
                onClick={() => {
                  history.push("/legal/privacy");
                }}
              >
                {t("Privacy")}
              </p>
              <p
                className={choose === 1 ? "active" : ""}
                onMouseEnter={() => {
                  setChoose(1);
                }}
                onMouseLeave={() => {
                  setChoose(type === "terms" ? 1 : 0);
                }}
                onClick={() => {
                  history.push("/legal/terms");
                }}
              >
                {t("Terms")}
              </p>
              <div style={{ width: `${selectWidth[choose]}px`, left: `${selectMove[choose]}px` }} />
            </div>
            {type === "privacy" && (
              <div style={{ padding: "40px 0" }} className="termContent">
                <div>Moneta Digital Privacy Notice</div>
                <p>
                  Moneta Digital Co. Ltd., a Republic of Seychelles duly incorporated International Business Company under No. 226407 in
                  Victoria, Republic of Seychelles (hereinafter “Company”) is committed to protecting privacy and to transparency in our
                  information practices. This Privacy Notice is incorporated into and made part of our User Agreement (collectively the
                  “Policy”) and explains how the Company (“we”, “us”, or “our”) we may collect, use, disclose, or otherwise process any
                  non-public personal information we collect from you (“user”, “your”, “customer”, “data subject” or “data owner”), or that
                  you may provide to us when using Company services, products, or technology (collectively, the “Services”).
                </p>
                <p style={{ fontWeight: 600 }}>
                  Your use of and access to the Services is expressly conditioned on your acceptance of the Policy terms and conditions.
                </p>
                <p>
                  <span style={{ fontWeight: 600 }}>1. Consent.</span>
                </p>
                <p>You hereby acknowledge and agree:</p>
                <p>(a) that you are legally an adult;</p>
                <p>(b) that you received this Privacy Notice from the Company</p>
                <p>(c) that you have read, understood, and agreed to the terms set out in this Privacy Notice.</p>
                <p>(d) that you freely give us your consent to process your personal data to provide you with the Services.</p>
                <p>
                  <span style={{ fontWeight: 600 }}>2. Withdrawal of Consent.</span>
                </p>
                <p>
                  You have the right to withdraw your consent given to the Company at any time. In addition, you have rights to access,
                  rectification, cancellation, and objection (“ARCO”). The procedure and mechanisms to withdraw your consent or exercise
                  your ARCO rights are listed below:
                </p>
                <p>
                  (a) you must send an e-mail {" "}
                  <a href="mailto:contact@monetadigital.com" target="_blank" rel="noreferrer">
                    contact@monetadigital.com
                  </a>{" "}
                  that must contain at least the following information and documentation:
                </p>
                <p style={{ paddingLeft: 32, fontSize: 14 }}>
                  a. Your name and address, or other means to communicate the answer to your request.
                </p>
                <p style={{ paddingLeft: 32, fontSize: 14 }}>
                  b. Your valid identifying documents to prove your identity (simple copy in printed or electronic format of your ID or
                  passport) or, if applicable, a simple copy in printed or electronic form of the power of attorney of the person that will
                  represent you, and their corresponding ID.
                </p>
                <p style={{ paddingLeft: 32, fontSize: 14 }}>c. A clear and precise indication that you desire to withdraw your consent;</p>
                <p style={{ paddingLeft: 32, fontSize: 14 }}>
                  d. A clear and precise indication that you desire to exercise your ARCO right(s);
                </p>
                <p style={{ paddingLeft: 32, fontSize: 14 }}>
                  e. Any user account info, element, or documentation to facilitate the location of your personal data.
                </p>
                <p>
                  (b) you must comply with our potential request(s) for further information in order to authenticate your identity. You
                  agree that we have the right to exhaust all means to verify your identity as consistent with your rights as a Data Subject
                  pursuant to the law(s) that apply to you and to our satisfaction as to the authenticity of documents provided and your
                  identity.
                </p>
                <p>
                  (c) Upon verification of your identity, we will confirm the acknowledgment of your withdrawal instruction and, if
                  possible, will destroy any personal data collected from you while at the same time complying with any statutory or
                  regulatory requirements that apply to us including, but not limited to, Anti-Money Laundering and Counter-Terrorism
                  Financing regulations.
                </p>
                <p>
                  <span style={{ fontWeight: 600 }}>3. Definitions</span>
                </p>
                <p>
                  Article 3 of the Privacy Law, Article 2 of the Privacy Regulations, and Article 3 of the Privacy Guidelines together
                  established the following definitions that shall apply:
                </p>
                <p>
                  3.1 ARCO: the data owner’s right of access, rectification, cancellation, and objection and mechanisms through which the
                  data owner can exercise ARCO rights or revoke consent
                </p>
                <p>
                  3.2 Cookies: a data files stored on the computer or electronic communications device of a user when browsing a specific
                  website, which allows the exchange of status information between such website and the user’s browser. The status
                  information may reveal means of session identification, authentication, or preferences of the user, as well as any data
                  stored by the browser regarding the internet site
                </p>
                <p>3.3 Data Controller: the individual or entity that decides how personal data is processed</p>
                <p>
                  3.4 Data Processor: the individual of entity that either alone or jointly with others processes personal data on behalf of
                  the Data Controller.
                </p>
                <p>3.5 Data Owner: the individual to whom the personal data relates - the user of the Services.</p>
                <p>
                  3.6 Personal Data: (aka “Personal Information”) refers to any information concerning an individual that may be identified
                  or identifiable, whether in physical or logical form, processed manually or automatically, from which the identity of an
                  individual is apparent or can be reasonably and directly ascertained by the entity holding the information, or when put
                  together with other information would directly and certainly identify an individual.
                </p>
                <p>
                  3.7 Sensitive personal data: refers to any attribute of your personal information that can discriminate, qualify, or
                  classify you such as your age, date of birth, marital status, government-issued identification numbers, account numbers,
                  and financial information.
                </p>
                <p>
                  3.8 Privileged Information: is all forms of information which, under other pertinent laws, constitute privileged
                  communication (i.e., lawyer-client, priest-confessor, doctor-patient).
                </p>
                <p>
                  3.9 Web beacons: visible or hidden image inserted within a website or email which are used to monitor user behavior and
                  obtain information such as the source IP address, browser used, operating system, and time accessed.
                </p>
                <p>
                  <span style={{ fontWeight: 600 }}>4. Designation of Data Controller</span>
                </p>
                <p>
                  The identity and address of the data controller can be reached at {" "}
                  <a href="mailto:contact@monetadigital.com" target="_blank" rel="noreferrer">
                    contact@monetadigital.com
                  </a>{" "}
                  and the identity and address of the person in charge of administering updating the Privacy Notice and/or Policy can be
                  reached at {" "}
                  <a href="mailto:contact@monetadigital.com" target="_blank" rel="noreferrer">
                    contact@monetadigital.com
                  </a>
                  .
                </p>
                <p>
                  <span style={{ fontWeight: 600 }}>5. Purposes for Data Processing</span>
                </p>
                <p>
                  When we ask for specific personal information from users, it is because we are required by law to process this
                  information, or it is relevant for particular purposes. If you choose not to provide us with this information, we will not
                  be able to offer our services. Any non-required information you provide to us is done so voluntarily. You decide whether
                  to provide us with this non-required information or not.
                </p>
                <p>
                  The principle of legitimacy requires Data Controllers to ensure personal information processing complies with Mexican and
                  International Law. As a Data Controller, we collect your personal information (and when required to serve you, sensitive
                  personal information) for a lawful purpose guided by statutes and regulations that apply to you. As such, please find the
                  following general purposes on why we collect your information:
                </p>
                <p>
                  5.1 To provide and maintain our services. We use the information collected to deliver the best quality of our services and
                  verify user identity. We use the IP address and unique identifiers stored in your device's cookies to help us authenticate
                  your identity and provide our service. Given our legal obligations and system requirements, we cannot provide you with
                  services without data like identification, contact information, and transaction-related information.
                </p>
                <p>
                  5.2 <i>To protect our users. </i>We use the information collected to protect our platform, your Users' accounts, and
                  archives. We use IP addresses and cookie data to protect against automated abuse such as spam, phishing, and Distributed
                  Denial of Service (DDoS) attacks. We analyze trading activity to detect suspicious behavior early to prevent potential
                  fraud and loss of funds to bad actors.
                </p>
                <p>
                  5.3 <i>To comply with legal and regulatory requirements. </i>Respect for the privacy and security of data you store with
                  us informs our approach to complying with regulations, governmental requests, and user-generated inquiries. We will not
                  disclose or provide any personal information to third-party sources without review from our legal case team and/or prior
                  consent from the user. As well, to comply with global industry regulatory standards including Anti-Money Laundering (AML),
                  Know-Your-Customer (KYC), and Counter-Terrorist Financing (CTF), we require user accounts to undergo user identity
                  authentication. These services are conducted by a processor who delivers all the results to us. The procedure mentioned
                  above is not automatic but is conducted only when the user decides to open a Company account. The user is the one who
                  provides the data and can choose, among several options, the documents to verify identity.
                </p>
                <p>
                  5.4 <i>To measure Site(s) performance. </i>We actively measure and analyze data to understand how our services are used.
                  This review activity is conducted by our operations team to continually improve our platform's performance and to resolve
                  issues with the user experience. We continuously monitor our systems' activity information and communications with users
                  to look for and quickly fix problems.
                </p>
                <p>
                  5.5 <i>To communicate with you. </i>
                  We use personal information collected, like an email address, to interact with users directly when providing customer
                  support on a ticket or to keep you informed on logins, transactions, and security. Without processing your personal
                  information for confirming each communication, we will not be able to respond to your submitted requests, questions, and
                  inquiries. All direct communications are kept confidential and reviewed internally for accuracy.
                </p>
                <p>
                  5.6 <i>To enforce our agreements and policies</i>
                  It is crucial for our users and us that we continually review, investigate, and prevent any potentially prohibited or
                  illegal activities that violate our policies. For the benefit of our entire user base, we carefully enforce our agreements
                  with third parties and actively investigate violations of our posted terms and conditions for any Services. We reserve the
                  right to terminate the provision of service to any user found engaging in activities that violate any of our policies or
                  rules.
                </p>
                <p>
                  For any specific purpose(s) that may not fall within these general categories, we shall undertake to inform you the
                  reasons behind collecting your personal data whilst complying with other statutory or regulatory requirements.
                </p>
                <p>
                  <span style={{ fontWeight: 600 }}>6. Transparency</span>
                </p>
                <p>
                  As a Data Subject, you have the right to know where your information is, what it will be used on, and why this information
                  is being collected. These include your rights as a data subject (which is influenced by your jurisdiction) and how these
                  can be exercised, the purpose and extent of the processing of your personal data, and the risks and safeguards involved.
                </p>
                <p>
                  <span style={{ fontWeight: 600 }}>7. Your Privacy Rights as a Data Subject.</span>
                </p>
                <p>
                  Your rights as a Data Subject are influenced by the laws and regulations that apply to you. As an international
                  organization, we endeavor to recognize such laws and regulations that apply to you.
                </p>
                <p>7.1 Privacy Rights of Mexican Citizens</p>
                <p style={{ paddingLeft: 21, fontSize: 14 }}>
                  The privacy and data protection rights (referred to herein collectively as “privacy rights”) of Mexican citizens are not
                  sectoral and generally accord with international correlative laws, standards, and norms. Such rights are set forth in
                  Article 16 of the Mexican Constitution; in the Federal Law on the Protection of Personal Data held by Private Parties (
                  <i>Ley Federal de Protección de Datos Personales en Posesión de los Particulares</i> ) (“Privacy Law”); in the Regulations
                  to the Federal Law on the Protection of Personal Data held by Private Parties (
                  <i>Reglamento de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares</i> ) (“Privacy
                  Regulations”); and in the Privacy Notice Guidelines (<i>Lineamientos del aviso de Privacidad</i> ) (“Privacy Guidelines”).
                  <br />
                  The Privacy Law and Regulations provide that all Mexican citizens have an inviolate right to the protection of their
                  personal data, as well as a right to access, rectify, cancel, or object (“ARCO”) to the use of such information.
                </p>
                <p>7.2. Recognition of Privacy Rights in other Jurisdictions</p>
                <p style={{ paddingLeft: 21, fontSize: 14 }}>
                  The Company’s treatment of Personal Information may be influenced by other jurisdictions and/or the rights of data
                  subjects in their respective countries. We adhere to rights expressly stated in such laws and regulations where applicable
                  and comply with the provisions set out by the law in each country to the extent relevant.
                </p>
                <p>
                  <span style={{ fontWeight: 600 }}>8. What Information is Collected.</span>
                </p>
                <p>
                  When you create a Company user account you provide us with personal information that includes the identification data
                  listed below:
                  <span style={{ display: "block", paddingTop: 12 }}>- Full Given Name and Surname without abbreviation</span>
                  <span style={{ display: "block", paddingTop: 12 }}>- Date of birth</span>
                  <span style={{ display: "block", paddingTop: 12 }}>- Gender</span>
                  <span style={{ display: "block", paddingTop: 12 }}>- Birth state and country</span>
                  <span style={{ display: "block", paddingTop: 12 }}>- Nationality</span>
                  <span style={{ display: "block", paddingTop: 12 }}>- Username and password</span>
                  <span style={{ display: "block", paddingTop: 12 }}>- E-mail address</span>
                  <span style={{ display: "block", paddingTop: 12 }}>- Full personal address and zip code</span>
                  <span style={{ display: "block", paddingTop: 12 }}>- Financial and wealth data</span>
                  <span style={{ display: "block", paddingTop: 12 }}>- Employment data (except trade union membership)</span>
                  <span style={{ display: "block", paddingTop: 12 }}>- Mobile phone number</span>
                  <span style={{ display: "block", paddingTop: 12 }}>
                    - Time-based One-Time Passwords (TOTPs used for 2FA verification, if enabled, e.g., Google Authenticator or Authy)
                  </span>
                  <span style={{ display: "block", paddingTop: 12 }}>
                    - Geolocation of the mobile device and/or the IP address of the computer from which you opened your account
                  </span>
                  <span style={{ display: "block", paddingTop: 12, paddingBottom: 10 }}>- A valid official ID</span>
                  The above information is required to process your user account opening. If you do not consent to the collection of one or
                  more of these types of data, we will not be able to serve you and thus you should not use Company Services.
                </p>
                <p>
                  <span style={{ fontWeight: 600 }}>9. Service Usage Information.</span>
                </p>
                <p>
                  Through your use of the Company Site(s) and our different Services, we also continuously monitor and obtain tracking
                  information related to usages such as:
                  <span style={{ display: "block", paddingTop: 12 }}>- access date & time</span>
                  <span style={{ display: "block", paddingTop: 12 }}>- device identification</span>
                  <span style={{ display: "block", paddingTop: 12 }}>- operating system</span>
                  <span style={{ display: "block", paddingTop: 12 }}>- browser type; and</span>
                  <span style={{ display: "block", paddingTop: 12 }}>- IP address.</span>
                </p>
                <p>
                  A Processor may collect this information under the supervision of the rightful Data Controller. This service usage data
                  helps us our systems to ensure that our interface is accessible for users across all platforms and can aid during criminal
                  investigations.
                </p>
                <p>
                  These information are required to continue using our services. If you do not consent to the collection of one or more of
                  these types of data, we will not be able to serve you and thus you should not use Company Services.
                </p>
                <p>
                  <span style={{ fontWeight: 600 }}>10. How We Protect Your Data.</span>
                </p>
                <p>
                  We have implemented security measures to ensure that your information is not lost, abused, or altered. Our data security
                  measures include, but are not limited to, PCI Scanning, Secured Sockets Layered encryption technology, pseudonymization,
                  internal data access restrictions, and strict physical access controls to buildings & files. For obvious security reasons,
                  we will be unable to share the specifics as to how these protections work together. We keep all Data secret, and we have
                  taken precautions with our personnel and any Data Processors we may use to process all user data as confidential.
                </p>
                <p>
                  It is impossible to guarantee 100% secure transmission of data over the Internet nor method of electronic storage. As
                  such, you understand the responsibility to take safety precautions to protect your personal information independently.
                </p>
                <p>
                  If you suspect that your personal information has been compromised, especially account and/or password information, please
                  lock your account and contact Company customer service immediately.
                </p>
                <p>
                  <span style={{ fontWeight: 600 }}>11. Information Sharing with Third Parties</span>
                </p>
                <p>
                  We may transfer your personal data to other companies or authorities for the lawful purposes set out in this Privacy
                  Notice. These data transfers include the following:
                </p>
                <p>
                  (a) Since we are part of an international group, your personal data may be communicated to other companies within the same
                  corporate group with the same data Privacy Notice, including our affiliates. Our affiliates have implemented measures and
                  policies for its customers' data protection. These policies are consistent with ours and try to comply with the provisions
                  set out by the law in each country and will abide by this Privacy Notice.
                </p>
                <p>
                  (b) We may send all or a part of your personal data to processors as service providers that support us with data
                  processing activities. These processors include (i) technology companies that provide us with communication or
                  infrastructure services; and (ii) companies that provide us with publicity or loyalty program management services. In all
                  these cases, we ensure that these service providers assume contractual obligations that allow our customers' personal data
                  is protected.
                </p>
                <p>
                  (c) Likewise, we may transfer your personal data to administrative or judicial authorities, when the transfer is necessary
                  or legally required when safeguarding the public interest, protection of rights, third parties' protection of rights, or
                  for the enforcement and administration of justice. Additionally, we may use or disclose your personal data when we
                  consider, in good faith, that the applicable law allows us to use or disclose your data. It includes, for example, when
                  such transfer is necessary to protect you, to answer to claims, or protect our property rights or security, our
                  affiliates, or third parties' rights.
                </p>
                <p>
                  (d) Your information may be communicated or transferred to a third party during a corporate transaction, including a
                  merger or an asset sale or an acquisition, and always in the best interests of the users.
                </p>
                <p>
                  (e) In other specific cases, when permitted by the applicable law or when it is express consent by you to perform the
                  transfer.
                </p>
                <p>
                  <span style={{ fontWeight: 600 }}>12. Proportionality of Use.</span>
                </p>
                <p>
                  When we collect information from or about you, these will be collected and processed in an adequate, relevant, suitable,
                  necessary, and not excessive in relation to the lawful purpose(s). Your data will be processed only if the purpose of the
                  processing could not reasonably be fulfilled by other means.
                </p>
                <p>
                  <span style={{ fontWeight: 600 }}>13. Procedure for Exercising Privacy Rights</span>
                </p>
                <p>
                  You always have the right to know what personal data we have about you, what we use it for, and the conditions of the use
                  we give it (Access). Likewise, it is your right to request the correction of your personal information if it is outdated,
                  inaccurate, or incomplete (Correction); that we remove it from our records or databases when you consider that it is not
                  being used per the principles, duties, and obligations provided for in the regulations (Cancellation or right to be
                  forgotten); you also have the right to receive your personal data provided to us in a structured, commonly used and
                  machine-readable format and, where technically feasible, you have the right to instruct us to transmit those data to
                  another controller without hindrance; as well as to oppose and object the use of your personal data for specific purposes
                  (Opposition). These rights are known as Privacy Rights.
                </p>
                <p>
                  To exercise any of the Privacy Rights (or other such rights granted to you by your jurisdiction), you must send an email
                  to{" "}
                  <a href="mailto:contact@monetadigital.com" target="_blank" rel="noreferrer">
                    contact@monetadigital.com
                  </a>
                  , which must contain the following information and documentation:
                </p>
                <p style={{ paddingLeft: 20, fontSize: 14 }}>
                  (f) Your name and address, or other means to communicate the answer to your request;
                </p>
                <p style={{ paddingLeft: 20, fontSize: 14 }}>
                  (g) The valid documents proving your identity (simple copy in printed or electronic format of your ID or passport) or, if
                  applicable, a simple copy in printed or electronic form of the power of attorney of the person that will represent you,
                  and their corresponding ID or passport;
                </p>
                <p style={{ paddingLeft: 20, fontSize: 14 }}>
                  (h) A clear and precise description of the data on which you are seeking to exercise any of the Privacy Rights, and
                </p>
                <p style={{ paddingLeft: 20, fontSize: 14 }}>
                  (i) Any other element or document that facilitates the location of your personal data.
                </p>
                <p>
                  In the case of requests for the rectification of personal data (Correction), you must also indicate the modifications to
                  be made and provide the documentation supporting your application.
                </p>
                <p>
                  We will comply with your request after authenticating your identity. You agree that we have the right to exhaust all means
                  to verify your identity in a manner(s) or method(s) that are consistent with your rights as a Data Subject in the law(s)
                  that apply to you and to our satisfaction as to the authenticity of documents provided and your identity. We will confirm
                  the acknowledgment of your withdrawal instruction and, if possible, will destroy (Cancellation) any personal data
                  collected from you whilst complying with any statutory or regulatory requirements that apply to us including, but not
                  limited to, Anti-Money Laundering and Counter-Terrorism Financing regulations.
                </p>
                <p>
                  The contact details of the person or department that will process the requests for the exercise of the Privacy Rights, as
                  well as to respond to any doubt that you could have concerning the treatment of your information, is found below in our
                  contact section.
                </p>
                <p>
                  <span style={{ fontWeight: 600 }}>14. Changes to the Privacy Notice</span>
                </p>
                <p>
                  On occasion, we may revise this Privacy Notice to reflect changes in law or our personal data collection and use
                  practices. The modified Privacy Notice will be published in visible places on our Company Site(s) and will be delivered to
                  you by email. You will be able to verify the Privacy Notice has been changed because the effective date will always be
                  present. In case Company makes any substantial change with the treatment of your personal data, we will let you know via
                  email and will announce such changes on our Company Site(s).
                </p>
                <p>
                  Every change made to our Privacy Notice will be effective the following day from its publication date. If you disagree
                  with the modifications, please reach out to us on the following e-mail {" "}
                  <a href="mailto:contact@Company.io" target="_blank" rel="noreferrer">
                    contact@Company.io
                  </a>
                  . If you are an existing user, your constant use of our Services after a Privacy Notice modification’s effective date
                  (wherein you will have been informed beforehand) means you consent to the new Privacy Notice to the extent permitted by
                  law.
                </p>
                <p>
                  We encourage you to review our Privacy Notice periodically. We will ask for your consent before using your information for
                  any purpose that is not covered in this Privacy Notice.
                </p>
                <p>
                  <span style={{ fontWeight: 600 }}>15. Contact Us</span>
                </p>
                <p>
                  We are committed to respecting the freedoms and rights of all our Users who have placed their trust in our service. If you
                  should have any questions or concerns regarding this Privacy Notice, or if you would like to file a Data Protection
                  request, please refer to our FAQ and contact us on our Support Page or email us at {" "}
                  <a href="mailto:contact@monetadigital.com" target="_blank" rel="noreferrer">
                    contact@monetadigital.com
                  </a>
                </p>
              </div>
            )}
            {type === "terms" && (
              <div style={{ padding: "40px 0" }} className="termContent">
                <div>Moneta User Agreement</div>
                <p>
                  <span style={{ fontWeight: 600 }}>1. General</span>
                </p>
                <p>
                  1.1 Whether by independently purchasing Moneta tokens (MMXN) directly from Moneta Digital Co. Ltd., a Republic of
                  Seychelles duly incorporated International Business Company under No. 226407 in Victoria, Republic of Seychelles
                  (hereinafter “Moneta”) or by signing up to use a third party service provider such as the Mexo Global Ltd., a British
                  Virgin Island entity duly incorporated under Company No. 2038445 and having its registered address in Craigmuir Chambers,
                  Road Town, Tortola, VG 1110, British Virgin Islands (hereinafter “Mexo”), where (MMXN) is offered by Moneta, through the
                  Mexo Site(s) and Mexo Services, or other third party providers contracted by Moneta, you agree to comply with and be
                  legally bound by this Moneta User Agreement, as revised from time to time.
                </p>
                <p>
                  1.2 If you do not agree to any of the terms set forth in this Moneta User Agreement, or any subsequent modification to the
                  same, you may not access or use any of the Moneta Services.
                </p>
                <p>
                  1.3 We may amend or modify this Agreement by posting on the Mexo Site(s) or emailing to you the revised Moneta User
                  Agreement, and the revised Agreement shall be effective at such time. We may (a) modify or discontinue any portion of the
                  Moneta Services, and (b) suspend or terminate your access to the Moneta Services, at any time, and from time to time,
                  without notice to you. You agree that we shall not be liable to you or any third party for any modification or termination
                  of the Moneta Services, or suspension or termination of your access to the Moneta Services, except to the extent otherwise
                  expressly set forth herein.
                </p>
                <p>
                  <span style={{ fontWeight: 600 }}>2. About Moneta Stablecoin (MMXN)</span>
                </p>
                <p>
                  Moneta issues and redeems Moneta Stablecoin(MMXN) tokens pegged to Mexican Pesos. If you cause to be issued MMXN 100.00,
                  Moneta holds reserves valued at 100 Mexican Pesos. The composition of the Reserves used to back Moneta Stablecoin (MMXN)
                  tokens is within the sole control and at the sole and absolute discretion of Moneta. Moneta Stablecoin (MMXN) are backed
                  by Moneta’s Reserves, including Fiat, but Moneta Stablecoin (MMXN) are not Fiat themselves. Moneta will not issue Moneta
                  Stablecoin (MMXN) for consideration consisting of the Digital Tokens; only Fiat will be accepted upon issuance. In order
                  to cause Moneta Stablecoin (MMXN) to be issued or redeemed directly by Moneta, you must be a verified customer of Moneta.
                  No exceptions will be made to this provision. The right to have Moneta Stablecoin (MMXN) redeemed or issued is a
                  contractual right personal to you. Moneta reserves the right to delay the redemption or withdrawal of Moneta Stablecoin
                  (MMXN) if such delay is necessitated by the illiquidity or unavailability or loss of any Reserves held by Moneta to back
                  the Moneta Stablecoin (MMXN), and Moneta reserves the right to redeem Moneta Stablecoin(MMXN) by in-kind redemptions of
                  securities and other assets held in the Reserves. Moneta makes no representations or warranties about whether Moneta
                  Stablecoin (MMXN) that may be traded on the Site at any point in the future, if at all.
                </p>
                <p>
                  <span style={{ fontWeight: 600 }}>3. Moneta ’s Relationship with You</span>
                </p>
                <p>
                  3.1 Moneta backs and allows users to buy, sell, exchange Moneta Stablecoin (MMXN) directly from Moneta, as well as through
                  third parties such as the Mexo in relation to your usage of Mexo Services. Moneta is an independent contractor for all
                  purposes.
                </p>
                <p>
                  3.2 Your Privacy. Protecting users’ privacy is important to Moneta. Please review our Privacy Policy to better understand
                  our commitment to maintaining your privacy, as well as our use and disclosure of your information.
                </p>
                <p>
                  3.3 Privacy of Others; Marketing & Advertising. If you receive information about another Moneta User using Moneta Services
                  or other means, you must keep the information confidential and only use it in relation to Moneta Services. You may not
                  disclose or distribute another Moneta User(s)’ information to a third party or use the information for marketing and/or
                  advertising purposes unless you receive the Moneta User(s)’ express consent to do so. You may not send unsolicited
                  email(s) or messages to a Moneta Users.
                </p>
                <p>
                  3.4 Intellectual Property. Moneta and all logos related to Moneta Services and/or Moneta Services are either trademarks or
                  registered marks of Moneta or its licensors. All products and company names are trademarksTM or registered® trademarks of
                  their respective holders. Use of them does not imply any affiliation with or endorsement by them.
                </p>
                <p>
                  3.5 Email, Password, and Account Security. You are responsible for maintaining adequate security and control of any and
                  all IDs, passwords, personal identification numbers (PINs), or any other codes that you use to access Moneta Services. You
                  are responsible for keeping your email address up to date in third party accounts where you access Moneta, including your
                  Mexo Account. Any change of email shall be notified in advance to Mexo to become effective.
                </p>
                <p>
                  3.6 Notices to you. You agree that Moneta may provide communications, requirements, announcements, and notifications about
                  your account to the electronic address established by you during your registration. You also assume responsibility to
                  regularly access Mexo Site(s) to review any notification(s) and/or announcement(s) published there.
                </p>
                <p>
                  3.7 Notices to Moneta. Moneta prefers receiving notices to via email at
                  <span onClick={() => window.open("mailto:contact@monetadigital.com", "_blank")} style={{ cursor: "pointer" }}>
                    contact@monetadigital.com
                  </span>
                </p>
                <p>
                  3.8 Validity of Notices. You agree that for any matter related to the provision of Moneta Services, such notices are only
                  to be between Moneta and you through the email provided at the time of registration or through an email that is
                  subsequently provided to Moneta. Said notices will be considered valid with the sole fact that Moneta can prove that the
                  email left Moneta’s servers. You cannot assume legal responsibility nor rights for any activities or information such as
                  winning and offers, which are not provided to you through Moneta ’s official electronic distribution channels.
                </p>
                <p>
                  <span style={{ fontWeight: 600 }}>4. Accounts</span>
                </p>
                <p>
                  4.1 Moneta Stablecoin Wallet. Your Moneta Stablecoin (MMXN) Wallet is an instrument that stores MMXN and whose operation
                  and service therein is maintained by Moneta. It is not a deposit account and therefore, it is neither covered by any local
                  insurance nor earns any interest. It is subjected to the rules of relevant legal authorities and Moneta or related third
                  parties and applicable AML and CTF Policies.
                </p>

                <p>
                  4.2 Eligibility. The access to Moneta Services, through either Mexo, or third party providers, is null and void where such
                  access or use if forbidden by or would constitute a breach of this or ancillary agreement or would be subject to sanctions
                  under applicable regulations. The access or use of Mexo Site(s) shall not be the reason for considering or recognizing any
                  interest, right, remedy, power, or privilege. Non-Eligible Persons that use Moneta Services, Mexo Site(s), or sites of
                  related third parties are in breach of this and related agreements and any funds, proceeds, or other property held in any
                  Moneta, Mexo, or third party Wallet within the related sites confiscated. Moneta may implement controls to restrict
                  participation from any jurisdiction prohibited under this section.
                  <br />
                  As an eligible person, you declare and guarantee the same eligibility requirements as set forth in the Mexo User Agreement
                  or other third party agreements of Moneta partners or affiliates.
                  <br />
                  Moneta reserves the right to allow third parties, such as Mexo, to close any of your accounts immediately, freeze your
                  funds, and/or liquidate any open positions if Moneta, Mexo, or other related third party determines that you are accessing
                  any of their services from any Forbidden Jurisdiction or have given false representations as to your location of
                  incorporation, establishment, citizenship, or place of residence.
                </p>
                <p>
                  4.3 Registration Process. You agree to provide true and valid contact information per the requirements for opening of a
                  Level 1 account set out in the Mexo registration page, such (but not limited to) the requirements and responsibilities in
                  the Mexo User Agreement, or as required by any other third party providers.
                </p>
                <p>
                  4.4 Legitimate Use. You acknowledge that the Data that is collected directly or indirectly by Moneta will be used for your
                  registration; therefore, you agree to provide legitimate and accurate Data per the requirements that Moneta establishes.
                </p>
                <p>
                  4.5 Validity of Electronic Signatures. Your provided username and password serve as authentication elements. Moneta may,
                  when it considers necessary or when legally required, provide you with additional authentication or identification
                  elements. Your authentication elements, including any additional elements as necessary, have the same evidentiary value as
                  a written signature in accordance with applicable law(s).
                </p>
                <p>
                  4.6 Third Party Applications. If you grant express permission to a third party to connect to your Moneta Stablecoin (MMXN)
                  Wallet, either through the third party’s product or through Moneta, you acknowledge that granting permission to a third
                  party to take specific actions on your behalf does not relieve you of any of your responsibilities under this Moneta User
                  Agreement. Further, you acknowledge and agree that you will not hold Moneta responsible for, and will indemnify Moneta
                  from, any liability arising from the actions or inactions of this third party in connection with the permissions you
                  grant.
                </p>
                <p>
                  <span style={{ fontWeight: 600 }}>5. Moneta Services</span>
                </p>
                <p>
                  5.1 Service Description. Through the Moneta Services and other services provided by Moneta and its affiliates, users can
                  deposit and withdraw Moneta Stablecoin (MMXN) in and out of their MMXN wallet.
                </p>
                <p>5.2 Moneta Service Changes. In the event of any special circumstances, Moneta will be entitled to the following:</p>
                <p style={{ paddingLeft: 32, fontSize: 14 }}>
                  5.2.1 Changes to Moneta Services. Moneta reserves the right to make changes to the content of this Moneta User Agreement,
                  the Fee Schedule, and/or other provisions and operation rules issued by Moneta at any time. Moneta will publish a notice
                  at a visible position within the Mexo Site(s) and will become immediately effective. If you continue to use the Moneta
                  Services, you are deemed to agree to the change of content published by Moneta. If you do not agree to the changed
                  content, you have the right to stop using the Moneta Services.
                </p>
                <p style={{ paddingLeft: 32, fontSize: 14 }}>
                  5.2.2 <i>Interruption or Termination of Moneta Service(s).</i> Moneta reserves the right to terminate all Moneta Services
                  according to this Moneta User Agreement, and this Moneta User Agreement shall terminate on the date of termination of the
                  entire service of Moneta. Upon termination of this Moneta User Agreement, you are not entitled to require Moneta to
                  continue to provide you with any services or to perform any other obligations, including but not limited to requiring
                  Moneta to retain or disclose any information in its original account to you or any third-party Information you have not
                  read or sent. The termination of this Moneta User Agreement shall not affect the breach of contract by the defaulting
                  Party.
                </p>
                <p>
                  5.3 In the event of the occurrence of any of the following circumstances, Moneta reserves the right to discontinue or
                  terminate the Moneta Services provided to you, without prior notice:
                </p>
                <p style={{ paddingLeft: 32, fontSize: 14 }}>
                  5.3.1 your personal information is untrue or inconsistent with the information at the time of registration, and you fail
                  to provide reasonable evidence of such information;
                </p>
                <p style={{ paddingLeft: 32, fontSize: 14 }}>
                  5.3.2 you violate the relevant laws and regulations applicable to Mexo Services or any of the terms and conditions under
                  this Moneta User Agreement;
                </p>
                <p style={{ paddingLeft: 32, fontSize: 14 }}>
                  5.3.3 attempts to gain unauthorized access to the Site or another User's account or assisting others trying to do so;
                </p>
                <p style={{ paddingLeft: 32, fontSize: 14 }}>
                  5.3.4 use Moneta Services to perform illegal activities such as money laundering, illegal gambling operations, financing
                  terrorism, or other criminal activities;
                </p>
                <p style={{ paddingLeft: 32, fontSize: 14 }}>
                  5.3.5 due to the requirements of the financial regulatory authorities, the Financial Intelligence Unit or the criminal,
                  political and legal departments;
                </p>
                <p style={{ paddingLeft: 32, fontSize: 14 }}>
                  5.3.6 for security reasons or other circumstances considered as relevant by Moneta;
                </p>
                <p style={{ paddingLeft: 32, fontSize: 14 }}>5.3.7. for any other reason established in this Moneta User Agreement.</p>
                <p>
                  5.4. After the interruption or termination of the Moneta Services, you shall deal independently and fully incur any costs,
                  disputes, or losses arising from any of the trade conducted before the suspension or termination of the Moneta Services
                  and you shall ensure that Moneta is kept free and safe of any costs or costs incurred. Because of the interruption or
                  termination, Moneta may, at any time, cancel any offer or pending transaction not yet fulfilled.
                </p>
                <p>
                  5.5 Usage of Third-Party Tellers. Moneta may introduce you to third party agents or “Tellers” for the purpose of
                  depositing funds into your Moneta account. While we try to verify each Teller, we will not assess the suitability,
                  legality or ability of any third-party deposit providers and you expressly waive and release the company and/or its
                  directors, officers and employees from any and all liability, claims or damages arising from or in any way related to the
                  third-party deposit service provider. Moneta will not be a party to disputes, negotiations of disputes between you and
                  such third-party providers. Responsibility for the decisions you make regarding Moneta Services offered via the software
                  or service (with all its implications) rests solely with you. We will not assess the suitability, legality, or ability of
                  any such third parties and you expressly waive and release the company and/or its directors, officers, and employees from
                  any and all liability, claims, causes of action, or damages arising from your use of the software or service, or in any
                  way related to the third parties introduced to you by the software or service.
                </p>
                <p>
                  <span style={{ fontWeight: 600 }}>6. User Responsibilities</span>
                </p>
                <p>
                  6.1 Responsibility to Non-infringement of Intellectual Property. You may not use the trademarks, signs, logos, emblems,
                  and service marks of Moneta without prior written consent from Moneta. If you are suspected of infringing upon the
                  legitimate rights and interests of others, such as intellectual property rights, Moneta has the right to determine the
                  alleged infringement in the case of the existence of the right to provide you with the necessary identity information.
                </p>
                <p>
                  6.2 Responsibility to Abide by Local and International Laws. You shall strictly abide by applicable national laws and
                  regulations, including without limitation, the AML and CTF provisions relevant to you.
                </p>
                <p>
                  6.3 Unauthorized Uses. Users are prohibited from using any Moneta Services, services provided to or in relation to other
                  users, and related third party services, which contravene the Moneta User Agreement or any other third party user
                  agreement. This prohibition is designed to protect Moneta users from wrongdoing and ensure compliance with local laws and
                  international norms. Moneta will report any suspicious activity to the relevant law enforcement agencies.
                </p>
                <p>
                  <span style={{ fontWeight: 600 }}>7. Disputes with Moneta</span>
                </p>
                <p>
                  7.1 Release of Moneta. You consequently and irrevocably release Moneta (and our parent, officers, directors, agents, joint
                  ventures, employees, and suppliers) from any type of civil, criminal, commercial, administrative, or any other kind of
                  liability or demands, present or future, known or unknown, related to any of the risks described herein, and therefore
                  irrevocably waive any action that may be taken to claim any type of liability from Moneta. You also agree not to hold
                  Moneta liable for anything that may arise from the wrongful or illicit use of Moneta Services and to indemnify Moneta for
                  any damage derived from such unlawful actions.
                </p>
                <p>
                  7.2 Arbitration. You and Mexo expressly agree, by common consent and without reservation, to resolve always to individual
                  and binding arbitration any dispute or claim that may arise concerning this contract or the use of the services provided
                  by Mexo.
                </p>
                <p>
                  7.3 Accordingly, you and Mexo waive resolving any dispute arising between both parties through a court, tribunal or Jury
                  Trial, or class arbitration, class action, or any other type of representative proceeding.
                </p>
                <p>
                  7.4 If for any reason, a claim must be heard before a Court rather than through arbitration, both parties waive any right
                  they may have to have a jury trial when applicable under applicable law. If the Law of any jurisdiction or Country or a
                  Court prevents the application of the limitation contemplated herein for a claim, in this case, this and only this claim
                  must be resolved before a Court. Mexo may appeal the decision of the latter if appropriate according to the applicable
                  law. The remaining claims will continue to be resolved through arbitration.
                </p>
                <p>
                  7.5 This Agreement shall be governed by, construed, and interpreted under the UNIDROIT Principle of International
                  Commercial Contracts (2016) and concerning issues not covered by such Principles by the Laws of the British Virgin
                  Islands.
                </p>
                <p>
                  7.6 In the event of any dispute arising out of or in connection with the present contract, the parties shall first refer
                  the dispute to proceedings under the ICC Mediation Rules. If the dispute has not been settled pursuant to the said Rules
                  within 30 days following the filing of a Request for Mediation or within such other period as the parties may agree in
                  writing, such dispute shall thereafter be finally settled under the Rules of Arbitration of the International Chamber of
                  Commerce by one arbitrator appointed in accordance with the said Rules of Arbitration. The place of arbitration shall be
                  the City of Mexico.
                </p>
                <p>
                  <span style={{ fontWeight: 600 }}>8. General Provisions</span>
                </p>
                <p>
                  8.1 Limitations of Liability. Except as may be provided for in this agreement, Moneta assumes no liability or
                  responsibility for and shall have no liability or responsibility for any claim, application, loss, injury, delay,
                  accident, cost, business interruption costs, or any other expenses (including, without limitation, attorneys’ fees or the
                  costs of any claim or suit), nor for any incidental, direct, indirect, general, special, punitive, exemplary, or
                  consequential damages, loss of goodwill or business profits, work stoppage, data loss, computer failure or malfunction, or
                  any and all other commercial losses (collectively, referred to herein as “Losses”) directly or indirectly arising out of
                  or related to:
                </p>
                <p style={{ paddingLeft: 32, fontSize: 14 }}>8.1.1 this agreement;</p>
                <p style={{ paddingLeft: 32, fontSize: 14 }}>8.1.2 the Moneta site, and your use of it;</p>
                <p style={{ paddingLeft: 32, fontSize: 14 }}>8.1.3 the services provided by Moneta, and your use of any of them;</p>
                <p style={{ paddingLeft: 32, fontSize: 14 }}>
                  8.1.4 any inaccurate, misleading, or incomplete statement by Moneta, or on the site regarding your Moneta Stablecoin
                  (MMXN) token wallet, whether caused by Moneta’s negligence or otherwise;
                </p>
                <p style={{ paddingLeft: 32, fontSize: 14 }}>
                  8.1.5 any failure, delay, malfunction, interruption or decision (including any decision by Moneta to vary or interfere
                  with your rights) by Tether in operating the Site or providing any Service;
                </p>
                <p style={{ paddingLeft: 32, fontSize: 14 }}>
                  8.1.6 any stolen, lost, or unauthorized use of your Digital Tokens Wallet information, any breach of security or data
                  breach related to your Digital Tokens Wallet information, or any criminal or other third party act affecting Moneta or any
                  associate; or,
                </p>
                <p style={{ paddingLeft: 32, fontSize: 14 }}>
                  8.1.7 any offer, representation, suggestion, statement, or claim made about Moneta, the site, or any service by any
                  associate;
                </p>
                <p style={{ paddingLeft: 32, fontSize: 14 }}>
                  8.1.8 any delay in withdrawal or redemption, or loss of value of Moneta Stablecoin (MMXN) or the reserves backing such
                  Moneta Stablecoin (MMXN) resulting from failure or insolvency of any bank, depository, custodian, borrower, or payment
                  processor holding or processing the assets backing Moneta Stablecoin (MMXN), or from the theft of such assets, or from
                  freezes, seizures, or other legal process asserted by a Government; or,
                </p>
                <p style={{ paddingLeft: 32, fontSize: 14 }}>
                  8.1.9 another person using your Moneta Stablecoin (MMXN), Moneta Stablecoin (MMXN) token wallet, account or password, with
                  or without your knowledge.
                </p>
                <p style={{ paddingLeft: 32, fontSize: 14 }}>
                  8.1.10 You hereby agree to release the associates from liability for any and all losses, and you shall indemnify and save
                  and hold the associates harmless from and against all losses. The foregoing limitations of liability shall apply whether
                  the alleged liability or losses are based on contract, negligence, tort, unjust enrichment, strict liability, violation of
                  law or regulation, or any other basis, even if the associates have been advised of or should have known of the possibility
                  of such losses and damages, and without regard to the success or effectiveness of any other remedies.
                </p>
                <p style={{ paddingLeft: 32, fontSize: 14 }}>
                  8.1.11 Some states do not allow the exclusion or limitation of incidental or consequential damages so the above limitation
                  or exclusion may not apply to you.
                </p>
                <p>
                  8.2 Disclaimer. The guarantees and undertakings of Moneta as outlined in this Moneta User Agreement are the sole
                  warranties and representations made by Moneta, in respect of the Moneta Services provided under this Moneta User
                  Agreement, and supersede any other of warranties and undertakings, whether written or verbal, expressed or implied. All
                  such warranties and undertakings represent only the commitment and obligations of Moneta; therefore, Moneta does not
                  guarantee that any third party will comply with the warranties and undertakings under this Moneta User Agreement.
                </p>
                <p>
                  8.3 Time Limitations on Claims. You agree that any claim you may have arising out of or related to your relationship with
                  Moneta must be filed within one (1) year after such claim arose. Otherwise, your claim is permanently barred.
                </p>
                <p>
                  8.4 Force Majeure. Moneta will have no responsibility or liability for any failure or delay in performance of any Moneta
                  Services, or any loss or damage that you may incur, due to any circumstance or event beyond the control of Moneta or any
                  of its Affiliates, including without limitation, extraordinary weather conditions, earthquake, acts of God, war, fire,
                  revolts, labor dispute, riot, accident, action of government, power failure, hack, equipment failure or software
                  malfunction.
                </p>
                <p>
                  8.5 <i>No Warranty.</i> SERVICES ARE PROVIDED “AS IS” AND WITHOUT ANY REPRESENTATION OF WARRANTY, WHETHER EXPRESS, IMPLIED
                  OR STATUTORY. MONETA, OUR PARENT, THE OFFICERS, DIRECTORS, AGENTS, JOINT VENTURERS, EMPLOYEES AND SUPPLIERS OF Mexo OR OUR
                  PARENT SPECIFICALLY DISCLAIM ANY IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
                  NON-INFRINGEMENT. Moneta does not have any control over the products or services that are paid for with Moneta Services
                  and Moneta cannot ensure that a buyer or a seller you are dealing with will actually complete the transaction or is
                  authorized to do so. Moneta does not guarantee continuous, uninterrupted or secure access to any part of Moneta Services,
                  and operation of our site may be interfered with by numerous factors outside of our control. Mexo will make reasonable
                  efforts to ensure that requests for electronic debits and credits involving bank accounts, credit cards, and check
                  issuances are processed in a timely manner but Mexo makes no representations or warranties regarding the amount of time
                  needed to complete processing because Moneta Services are dependent upon many factors outside of our control, such as
                  delays in the banking system or the U.S. or international mail service. Some states do not allow the disclaimer of implied
                  warranties, so the foregoing disclaimers may not apply to you. This paragraph gives you specific legal rights and you may
                  also have other legal rights that vary from state to state.
                </p>
                <p>
                  8.6 Effectiveness and alteration of Agreement. This Agreement takes effect immediately on the date of publication, and
                  when you click on the agreement button when registered, it is legally binding for you and Mexo when you use Mexo Services.
                </p>
                <p>
                  8.7 <i>Termination of Moneta User Agreement.</i> You agree that Moneta shall have the right to terminate this agreement
                  for any reason without prior notice. Moneta does not need to assume any obligations to you or any third party for this
                  purpose. Moneta has the right to continue to keep the information, records, and other information relating to the account
                  and the use of the data, subject to any regulation or law related to privacy and our Privacy Policy. In such case, Moneta
                  undertakes to notify you by e-mail of said circumstances immediately. Therefore, you agree that your account service can
                  be terminated or that your account can be permanently frozen (canceled) after Moneta’s review and consideration of the
                  events described in Unauthorized Uses or any other specific provision in this Moneta User Agreement. You are not obliged
                  to keep or disclose any information in your account unless there is a specific requirement from a government authority for
                  you to do so, and you agree that Moneta does not assume any obligation to forward to you or a third party any information
                  you have not read.
                </p>
                <p>
                  <span style={{ fontWeight: 600 }}>9. Final Provisions</span>
                </p>
                <p>
                  9.1 <i>Severability.</i> If any part, term, or provision of this Agreement is held void, illegal, unenforceable, or in
                  conflict with any applicable law or jurisdiction, the validity of this Agreement will not be affected thereby except to
                  the extent such provision will be so held void, illegal or otherwise unenforceable. If a general provision is inconsistent
                  or conflicting with a specific rule, the latter will have priority.
                </p>
                <p>
                  9.2 <i>Entire Agreement.</i> This User Agreement contains the complete understanding between the Parties. The Parties
                  agree to abide by the terms and conditions established herein, replacing, and leaving without effects any prior oral or
                  written agreement or negotiation between them.
                </p>
                <p>
                  9.3 <i>Conjunction with Other Agreements.</i> The contents of this User Agreement in its entirety work alongside all
                  various types of rules Moneta has released to date or may be released in the future, including the Mexo User Agreement,
                  Privacy Policy, Over The Counter Policy, and Derivatives Trading Policy. All rules are an integral part of this Moneta
                  User Agreement and have the same legal effect as any third party agreements entered in connection with the purchase or
                  sale of Moneta Stablecoin (MMXN). Except as otherwise expressly stated, any services provided by Moneta and its affiliates
                  are governed by this Moneta User Agreement.
                </p>
                <p>
                  9.4 <i>Non-Waiver or Rights.</i> The failure of Moneta at any time to require performance by you of any provision hereof
                  shall not affect the right of Moneta to request performance at any time thereafter, nor shall the waiver of Moneta of a
                  breach of any provision hereof be taken or held to be a waiver of a provision itself. Moneta does not waive any rights not
                  covered by this Moneta User Agreement, to the extent that the law applies to Moneta, which reserves the right to waive or
                  cancel further liabilities.
                </p>
                <p>
                  9.5 <i>Assignment.</i> You may not assign or transfer any right to use the Services or any of your rights or obligations
                  under this Agreement without Moneta’s prior consent. Moneta may assign or transfer any or all its rights or obligations
                  under this Agreement, in whole or in part, without notice or obtaining your consent or approval.
                </p>
                <p>
                  9.6 <i>Language.</i> Moneta may endeavor to provide this Moneta User Agreement in languages other than English, and are
                  enforced in both languages, and which have equal legal force. In the event of any conflict between the provisions of the
                  Moneta User Agreement prepared in different languages, the respective provision in English shall prevail.
                </p>
              </div>
            )}
          </div>
        </div>
      </Wrapper>
    </RootLayout>
  );
}

export default LegalPage;
