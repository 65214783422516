import styled from "styled-components";
import { useTranslation } from "react-i18next";

import Button from "@Components/Button";

const Wrapper = styled.div`
  background: #fff;
  width: fit-content;
  min-width: 100%;
  padding: 160px 120px 190px 120px;
  > .inner {
    width: 100%;
    max-width: 1400px;
    min-width: 1200px;
    min-height: 440px;
    margin: 0 auto;
    /* min-height: 560px; */
    position: relative;
    > .greenBg {
      position: absolute;
      width: 400px;
      height: 100%;
      background: #00ec7d;
      top: 0;
      left: 0;
    }
    > .line {
      position: absolute;
      width: 0;
      height: 1px;
      background: rgba(8, 15, 42, 0.4);
      left: 0;
      top: 208px;
      animation: Stretch 1s linear forwards;
      @keyframes Stretch {
        0% {
          width: 0;
        }

        100% {
          width: 82%;
        }
      }
    }
    > .getButton {
      position: absolute;
      right: 0;
      top: 136px;
      z-index: 2;
      .x-button {
        font-size: 14px;
      }
    }
    > .content {
      position: relative;
      align-items: stretch;
      width: 100%;
      min-height: 440px;
      > .left {
        width: 400px;
        font-size: 48px;
        font-family: CASaygonText-Medium, CASaygonText;
        font-weight: 500;
        color: #080f2a;
        line-height: 58px;
        padding: 90px 0 0 72px;
      }
      > .right {
        width: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 112px 0 0 30px;
        > :first-child {
          font-size: 16px;
          font-family: CASaygonText-Regular, CASaygonText;
          font-weight: 400;
          color: #080f2a;
          line-height: 24px;
          width: 360px;
        }
        > .types {
          padding-top: 98px;
          padding-bottom: 24px;
          flex: 1;
          li {
            border: 1px solid #0c1125;
            width: 24%;
            height: 100%;
            .line {
              height: 100%;
              min-width: 10px;
              background: #28e775;
            }
            .exact {
              height: 100%;
              padding: 26px 16px;
              align-items: flex-start;
              .text {
                font-weight: 500;
                font-size: 16px;
                line-height: 18px;
                color: #000000;
              }
              .logo {
                img {
                  height: 24px;
                  max-width: 100%;
                }
                p {
                  margin-left: 6px;
                  font-weight: 500;
                  font-size: 15px;
                  color: #2c2b2b;
                }
              }
            }
            &.link {
              cursor: pointer;
              transition: all 0.2s linear;
              &:hover {
                transform: translateY(-10px);
              }
            }
          }
        }
      }
    }
  }
`;

const arr: Array<{ img: string; name: string; link?: string; remark?: string }> = [
  {
    img: "bank.svg",
    name: "Bank Transfer Tips",
    remark: "Bank Transfer",
  },
  {
    img: "mercado.png",
    name: "with Mercado Pago",
  },
  {
    img: "banxa.png",
    name: "with Banxa",
  },
  {
    img: "trubitColor.png",
    name: "P2P Commerce in Trubit Pro",
    link: "https://www.trubit.com/otc/",
  },
];

function GetMmxn() {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <div className="inner">
        <div className="greenBg" />
        <div className="line" />
        <div className="getButton">
          <Button
            theme="dark"
            onClick={() => {
              window.open("https://www.trubit.com/otc/trade/MMXN", "_blank");
            }}
          >
            {t("Buy MMXN now")}
          </Button>
        </div>
        <div className="content row-start">
          <div className="left">{t("Buy and Sell MMXN")}</div>
          <div className="right">
            <p>{t("Buy with bank")}</p>
            <ul className="types row-between">
              {arr.map(item => (
                <li
                  className={`row-start ${item.link && "link"}`}
                  key={item.img}
                  onClick={() => {
                    if (item.link) {
                      window.open(item.link, "_blank");
                    }
                  }}
                >
                  <div className="line" />
                  <div className="col-between exact">
                    <p className="text">{t(item.name)}</p>
                    <div className="logo row-start">
                      <img src={require(`@Assets/images/home/${item.img}`)} alt="icon" />
                      {item.remark && <p>{t(item.remark)}</p>}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default GetMmxn;
