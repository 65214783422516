import styled from "styled-px2vw";
import Button from "@Components/Mobile/Button";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  overflow: hidden;
  padding-bottom: 140px;
  > .top {
    padding-top: 168px;
    background-image: url(${require("@Assets/images/header/mobileTopBg.png")});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    > img {
      width: 603px;
    }
  }
  > .bottom {
    > :first-child {
      margin-top: 22px;
      margin-bottom: 40px;
      width: 455px;
    }
    > h2 {
      font-size: 43px;
      font-family: CASaygonText-Regular, CASaygonText;
      font-weight: 400;
      color: #ffffff;
      line-height: 61px;
      padding-bottom: 10px;
      width: 700px;
      text-align: center;
    }
    > h3 {
      font-size: 33px;
      max-width: 450px;
      font-family: CASaygonText-Light, CASaygonText;
      font-weight: 300;
      color: rgba(255, 255, 255, 0.6);
      line-height: 40px;
      padding-bottom: 120px;
      text-align: center;
    }
    > .x-button {
      width: 250px;
      height: 108px;
      font-size: 26px;
      font-family: CASaygonText-Regular, CASaygonText;
      font-weight: 400;
    }
  }
`;

function TopPannel() {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <div className="top col-center">
        <img src={require("@Assets/images/home/mobileTop.png")} alt="MMXN" />
      </div>
      <div className="bottom col-center">
        <img src={require("@Assets/images/header/title.svg")} alt="MMXN" />
        <h2>{t("topTitle")}</h2>
        <h3>{t("topSubTitle")}</h3>
        <Button
          theme="light"
          onClick={() => {
            window.open("https://www.trubit.com/otc/trade/MMXN", "_blank");
          }}
        >
          {t("Get MMXN Button")}
        </Button>
      </div>
      {/* <div className="right">
          <img src={require("@Assets/images/home/topRight.png")} alt="MMXN" />
        </div> */}
    </Wrapper>
  );
}

export default TopPannel;
