/* eslint-disable max-len */
interface IQuotes {
  text: string;
  logo: string;
}

const QUOTES_LIST: Array<IQuotes> = [
  {
    text: "Bowen Zhou, CEO de Moneta Digital, presentó a mediados de junio el token MMXN \"la primera stablecoin mexicana\" con una tasa de conversión que promete ser siempre de 1 MMXN a 1 peso mexicano.",
    logo: `${require("@Assets/images/quotes/es/0.png")}`,
  },
  {
    text: "“¿Se puede uno proteger de los fraudes en las finanzas descentralizadas sin ser un especialista en seguridad informática? Sí. Y no es tan difícil como se puede llegar a imaginar. Hay que abrir bien grande los ojos y seguir estos pasos que nos recomienda José Guadiana, VP de operaciones de Moneta.”",
    logo: `${require("@Assets/images/quotes/es/1.png")}`,
  },
  {
    text: "“Los beneficios de las stablecoins incluyen pagos con menores costos involucrados, más seguros, en tiempo real y más competitivo en comparación con lo que los consumidores y las empresas usan hoy en día.“",
    logo: `${require("@Assets/images/quotes/es/2.png")}`,
  },
  {
    text: "“Una Stablecoin vinculada al valor del peso mexicano, que apunta a eliminar la necesidad de los criptoinversores mexicanos tengan que estar haciendo tantas conversiones y que puedan pensar en su propia moneda.”",
    logo: `${require("@Assets/images/quotes/es/3.png")}`,
  },
  {
    text: "“MMXN ha desarrollado planes fiat - crypto como medio de intercambio para productos financieros, remesas, pagos y reserva de valor esto con la finalidad de aumentar la velocidad de las transacciones y disminuir el costo de la transferencia de valor sin fronteras”",
    logo: `${require("@Assets/images/quotes/es/4.png")}`,
  },
];

export default QUOTES_LIST;
