import { RouteConfig } from "react-router-config";

import About from "@Pages/desktop/DesktopAbout";
import FAQ from "@Pages/desktop/DesktopFAQ";
import Home from "@Pages/desktop/DesktopHome";
import Legal from "@Pages/desktop/DesktopLegal";

import MobileHome from "@Pages/mobile/MobileHome";
import MobileFAQ from "@Pages/mobile/MobileFAQ";
import MobileAbout from "@Pages/mobile/MobileAbout";
import MobileLegal from "@Pages/mobile/MobileLegal";

export interface RouteModel extends RouteConfig {
  auth?: boolean;
  path: string;
}

const Routes: RouteModel[] = [
  { path: "/", component: Home, exact: true },
  { path: "/about", component: About, exact: true },
  { path: "/faq", component: FAQ, exact: true },
  { path: "/legal/:type", component: Legal }, // type: privacy | terms

  { path: "/mobile", component: MobileHome, exact: true },
  { path: "/mobile/about", component: MobileAbout, exact: true },
  { path: "/mobile/faq", component: MobileFAQ, exact: true },
  { path: "/mobile/legal/:type", component: MobileLegal, exact: true }, // type: privacy | terms
];

export default Routes;
