import MobileLayout from "@Layout/MobileLayout";
import styled from "styled-px2vw";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  background: linear-gradient(118deg, #080f2a 0%, #151f44 100%);
  > .top {
  padding-bottom: 140px;
    width: 100%;
    padding: 168px 50px 98px;
    background-image: url(${require("@Assets/images/header/mobileTopBg.png")});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    > img {
      width: 536px;
    }
    > div {
      text-align: center;
      > .title {
        font-size: 66px;
        font-family: CASaygonText-Bold, CASaygonText;
        color: #00ec7d;
        padding: 100px 0 50px;
      }
      > p {
        word-break: break-word;
        font-size: 24px;
        font-family: CASaygonText-Regular, CASaygonText;
        font-weight: 400;
        color: #ffffff;
        line-height: 36px;
      }
    }
  }
  > .bottom {
    background: #fff;
    position: relative;
    overflow: hidden;
    padding: 50px 50px 0;
    > .content {
      position: relative;
      > .line {
        height: 1px;
        width: 96px;
        background: rgba(8, 15, 42, 0.5);
        margin: 0 auto 44px;
      }
      > .ourPromise {
        font-size: 24px;
        font-family: CASaygonText-Regular, CASaygonText;
        font-weight: 400;
        color: #080f2a;
        line-height: 36px;
        text-align: center;
      }
      .ourStyle {
        background: url(${require("@Assets/images/about/fillbg.svg")}) no-repeat;
        background-size: 750px 658px;
        background-position: center;
        align-items: flex-start;
        padding-top: 132px;
        width: 100%;
        .styleItem {
          > :first-child {
            align-items: flex-end;
            > img {
              width: 162px;
              height: auto;
            }
            > .styleLine {
              margin-right: -50px;
              height: 1px;
              background: rgba(8, 15, 42, 0.4);
              width: 503px;
            }
          }
          > h4 {
            font-size: 54px;
            font-family: CASaygonText-Medium, CASaygonText;
            font-weight: 500;
            color: #080F2A;
            line-height: 64px;
            margin: 46px 0 24px;
          }
          > .des {
            font-size: 24px;
            font-family: CASaygonText-Regular, CASaygonText;
            font-weight: 400;
            color: #080F2A;
            line-height: 36px;
          }
        }
        .missionOuter {
          margin-top: 220px;
          margin-bottom: 262px;
          .mission {
            > div {
              > .styleLine {
                margin-left: -50px;
                margin-top: 10px;
                margin-right: 0;
              }
            }
            > img {
              width: 215px;
              height: auto;
            }
            > h4 {
              margin-top: -20px;
            }
          }
        }
      }
    }
  }
`;

function AboutPage() {
  const { t } = useTranslation();
  return (
    <MobileLayout>
      <Wrapper>
        <div className="top">
          <img src={require("@Assets/images/about/earth.svg")} alt="earth" />
          <div>
            <h2 className="title">{t("About Moneta")}</h2>
            <p>{t("about1")}</p>
            <p style={{ marginTop: 14 }}>{t("about1.1")}</p>
          </div>
        </div>

        <div className="bottom">
          <div className="content">
            <div className="line" />
            <div className="row-end ourPromise">{t("about2")}</div>
            <div className="ourStyle col-between">
              <div className="styleItem">
                <div className="row-between">
                  <img src={require("@Assets/images/about/vision.svg")} alt="vision" />
                  <div className="styleLine" />
                </div>
                <h4>{t("Our Vision")}</h4>
                <p className="des">{t("vision text")}</p>
              </div>
              <div className="missionOuter">
                <div className="styleItem mission">
                  <div className="row-between" style={{ alignItems: "flex-start" }}>
                    <div className="styleLine" />
                    <img src={require("@Assets/images/about/mission.svg")} alt="vision" />
                  </div>
                  <h4>
                    {t("Our Mission")}
                    <div className="line" />
                  </h4>
                  <p className="des">{t("mission content")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </MobileLayout>
  );
}

export default AboutPage;
