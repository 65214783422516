import { useRef, useState, useEffect } from "react";
import styled from "styled-px2vw";
import { useTranslation } from "react-i18next";
import Swiper from "swiper";
import "swiper/swiper-bundle.css";
import EN from "@/config/en";
import ES from "@/config/es";

const Wrapper = styled.div`
  min-width: 100%;
  background: #080f2a;
  > .inner {
    position: relative;
    margin: 0 auto;
    width: 750px;
    height: 859px;
    background-image: url(${require("@Assets/images/quotes/quotes_m_bg.png")});
    background-position: center;
    background-size: 750px 859px;
    background-repeat: no-repeat;
    .swiper-slide {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 750px;
      color: #ffffff;
      h4 {
        margin-top: 93px;
        margin-bottom: 79px;
        font-size: 54px;
        font-family: CASaygonText-Medium, CASaygonText;
        font-weight: 500;
        color: #ffffff;
        line-height: 64px;
      }
      > .line {
        width: 80px;
        height: 5px;
        background: #00ec7d;
      }
      p {
        margin: 86px 0 40px 0;
        width: 650px;
        text-align: center;
        font-size: 24px;
        font-family: CASaygonText-Regular, CASaygonText;
        font-weight: 400;
        color: #ffffff;
        line-height: 36px;
      }
      img {
        width: 300px;
        height: 80px;
      }
    }
    .swiper-pagination {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      li {
        transition: all 0.3s ease-in-out;
        &.active {
          width: 26px;
          height: 8px;
          background: #00ec7d;
          border-radius: 8px;
        }
        &.default {
          width: 8px;
          height: 8px;
          background: #838794;
          border-radius: 50%;
        }
      }
      li + li {
        margin-left: 14px;
      }
    }
  }
`;

interface IQuotes {
  text: string;
  logo: string;
}

function Quotes() {
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const domRef = useRef<HTMLDivElement>(null);
  const swiperRef = useRef<Swiper>();

  const [array, setArray] = useState<IQuotes[]>([]);
  const [active, setActive] = useState(0);

  useEffect(() => {
    if (array.length === 0) return undefined;
    const mySwiper = new Swiper(domRef.current!, {
      slidesPerView: "auto",
      // loop: true,
      on: {
        transitionStart: () => {
          setTimeout(() => {
            if (mySwiper) {
              setActive(mySwiper.activeIndex);
            }
          }, 100);
        },
      },
    });
    swiperRef.current = mySwiper;
    return () => swiperRef.current!.destroy(true, true);
  }, [array]);

  useEffect(() => {
    const Lang = language === "en-us" ? EN : ES;
    setArray(Lang);
  }, [language]);

  return (
    <Wrapper>
      <div className="inner">
        <div className="swiper-container" ref={domRef}>
          <div className="swiper-wrapper">
            {array.map((item, index) => (
              <div key={index} className="swiper-slide">
                <h4>{t("Media")}</h4>
                <div className="line" />
                <p>{item.text}</p>
                <img src={item.logo} alt="logo" />
              </div>
            ))}
          </div>
        </div>
        <ul className="swiper-pagination">
          {array.map((item, index) => (
            <li className={`${index === active ? "active" : "default"}`} key={index} />
          ))}
        </ul>
      </div>
    </Wrapper>
  );
}

export default Quotes;
