import { useState, useEffect } from "react";
import styled, { createGlobalStyle } from "styled-px2vw";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { lock, unlock } from "tua-body-scroll-lock";

import Button from "./Button";

const GlobalStyle = createGlobalStyle`
  body {
    background: #080F2A;
  }
`;

const Wrapper = styled.header<{
  scribe: boolean;
}>`
  position: fixed;
  top: 0;
  z-index: 101;
  width: 100%;
  &::before {
    display: ${props => (props.scribe ? "none" : "block")};
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 12px;
    background: #00ec7d;
  }
  > .inner {
    height: 168px;
    padding: 0 50px;
    background: ${props => (props.scribe ? "#00ec7d" : "0 0")};
    transition: background-color 0.4s ease-in-out;
    > .logo {
      width: 218px;
      height: 40px;
    }
    > .right {
      > :first-child {
        margin-right: 60px;
        width: 209px;
        height: 70px;
        > button {
          width: 100%;
          height: 100%;
          font-size: 24px;
        }
      }
      > i {
        color: ${props => (props.scribe ? "#080F2A" : "#fff")};
        font-size: 58px;
      }
    }
  }
  .modal {
    position: fixed;
    overflow: hidden;
    width: 100%;
    height: 100%;
    top: 0;
    opacity: 0;
    z-index: 0;
    transition: opacity 0.2s linear;
    &.modalActive {
      opacity: 1;
      z-index: 19;
    }
    > .modalBg {
      background-color: rgba(10, 10, 10, 0.6);
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  > .sidebar {
    height: 100%;
    background: #080f2a;
    width: 540px;
    right: 0;
    top: 0;
    position: fixed;
    z-index: 20;
    transform: translateX(100%);
    transition: transform 0.4s ease;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 50px;
    padding-top: 62px;
    padding-bottom: 60px;
    &.sidebarActive {
      transform: translateX(0);
    }
    > .iconclose {
      color: #fff;
      font-size: 58px;
    }
    > nav {
      margin-top: 200px;
      align-items: flex-end;
      > p {
        font-size: 40px;
        font-family: CASaygonText-Medium, CASaygonText;
        font-weight: 500;
        color: #ffffff;
        margin-bottom: 50px;
        text-align: right;
      }
    }
    > .languageSwitch {
      margin-top: 157px;
      color: #fff;
      > p {
        font-size: 40px;
        font-family: CASaygonText-Medium, CASaygonText;
        font-weight: 500;
        &.selectedLan {
          color: #00ec7d;
          font-weight: 500;
        }
      }
      > div {
        margin: 0 14px;
        font-size: 40px;
      }
    }
  }
  > .fall {
    animation: smoothScroll 0.4s forwards;
    @keyframes smoothScroll {
      0% {
        transform: translateY(-100%);
      }
      100% {
        transform: translateY(0px);
      }
    }
  }
  > .rising {
    animation: smoothScrollTop 0.4s forwards;
    @keyframes smoothScrollTop {
      0% {
        transform: translateY(0px);
      }
      100% {
        transform: translateY(-100%);
      }
    }
  }
`;

const navList = [
  {
    name: "About MMXN",
    path: "/mobile/about",
  },
  {
    name: "Blog",
    path: "https://monetadigital.medium.com/",
  },
  {
    name: "FAQ",
    path: "/mobile/faq",
  },
];

function MobileHeader() {
  const { t, i18n } = useTranslation();
  const [sidebarActive, toggleSidebarActive] = useState<boolean>(false);
  const [scollType, setScollType] = useState<boolean>(false);
  const [scribeType, setScribeType] = useState<boolean>(false);
  const body = document.querySelector("body");
  const getScrollTop = () => {
    let scrollTop = 0;
    if (document.documentElement && document.documentElement.scrollTop) {
      scrollTop = document.documentElement.scrollTop;
    } else if (document.body) {
      scrollTop = document.body.scrollTop;
    }
    return scrollTop;
  };

  const getScollType = () => {
    let beforeScollh = 0;
    window.addEventListener("scroll", () => {
      const afterScollh = getScrollTop();
      const differH = afterScollh - beforeScollh;
      if (differH === 0) return;
      const scollTypeInner = differH > 0;
      const scribeTypeInner = afterScollh >= 102;
      setScollType(scollTypeInner);
      setScribeType(scribeTypeInner);
      beforeScollh = afterScollh;
    });
  };

  useEffect(() => {
    getScollType();
    return () => getScollType();
  }, []);

  const changeLocale = (lan: string): void => {
    i18n.changeLanguage(lan);
    localStorage.setItem("lang", lan);
  };

  const currentLang = localStorage.lang === "en-us" ? "en-us" : "es-es";

  return (
    <>
      <GlobalStyle />
      <Wrapper scribe={scribeType}>
        <div className="line" />
        <div className={`inner row-between ${scribeType && scollType ? "rising" : "fall"}`}>
          <img
            src={require(`@Assets/images/header/${scribeType ? "active" : ""}logo.svg`)}
            alt="logo"
            className="logo"
            onClick={() => {
              window.open("/mobile", "_self");
            }}
          />
          <div className="right row-start">
            <div>
              <Button
                theme={scribeType ? "dark" : "light"}
                onClick={() => {
                  window.open("https://www.trubit.com/otc/trade/MMXN", "_blank");
                }}
              >
                {t("Get MMXN Button")}
              </Button>
            </div>
            <i
              className="iconfont iconmenu"
              onClick={() => {
                const sidebar: any = document.querySelector(".sidebar");
                lock(body);
                lock(sidebar);
                toggleSidebarActive(true);
              }}
            />
          </div>
        </div>
        <div
          className={classnames({
            modal: true,
            modalActive: sidebarActive,
          })}
          style={{
            pointerEvents: sidebarActive ? "auto" : "none",
          }}
        >
          <div
            className="modalBg"
            onClick={() => {
              const sidebar: any = document.querySelector(".sidebar");
              toggleSidebarActive(false);
              unlock(body);
              unlock(sidebar);
            }}
          />
        </div>

        <div
          className={classnames({
            sidebar: true,
            sidebarActive,
          })}
        >
          <i
            className="iconfont iconclose"
            onClick={() => {
              const sidebar: any = document.querySelector(".sidebar");
              toggleSidebarActive(false);
              unlock(body);
              unlock(sidebar);
            }}
          />
          <nav className="col-start">
            {navList.map(item => (
              <p
                className="navItem"
                key={item.name}
                onClick={() => {
                  const sidebar: any = document.querySelector(".sidebar");
                  toggleSidebarActive(false);
                  unlock(body);
                  unlock(sidebar);
                  window.open(item.path, "_self");
                }}
              >
                {t(item.name)}
              </p>
            ))}
          </nav>
          <div className="languageSwitch row-center">
            <p onClick={() => changeLocale("es-es")} className={currentLang === "es-es" ? "selectedLan" : ""}>
              ES
            </p>
            <div> | </div>
            <p onClick={() => changeLocale("en-us")} className={currentLang !== "es-es" ? "selectedLan" : ""}>
              EN
            </p>
          </div>
        </div>
      </Wrapper>
    </>
  );
}

export default MobileHeader;
