import styled from "styled-components";
import Button from "@Components/Button";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  height: 840px;
  background-image: url(${require("@Assets/images/home/topBg.png")});
  background-position: right bottom;
  background-repeat: no-repeat;
  padding: 0 120px;
  > .inner {
    margin: 0 auto;
    max-width: 1400px;
    min-width: 1200px;
    height: 840px;
    > .left {
      img {
        height: 59px;
      }
      h2 {
        width: 750px;
        margin: 33px 0 0 0;
        font-size: 48px;
        font-family: CASaygonText-Regular, CASaygonText;
        font-weight: 400;
        color: #ffffff;
        line-height: 69px;
      }
      h3 {
        width: 750px;
        font-size: 37px;
        font-family: CASaygonText-Light, CASaygonText;
        font-weight: 300;
        color: rgba(255, 255, 255, 0.6);
        line-height: 40px;
        margin: 7px 0 111px;
      }
    }
    > .right {
      width: 464px;
      height: 407px;
    }
  }
`;

function TopPannel() {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <div className="inner row-between">
        <div className="left">
          <img src={require("@Assets/images/header/title.svg")} alt="MMXN" />
          <h2>{t("topTitle")}</h2>
          <h3>{t("topSubTitle")}</h3>
          <Button
            theme="light"
            onClick={() => {
              window.open("https://www.trubit.com/otc/trade/MMXN", "_blank");
            }}
          >
            {t("Get MMXN Button")}
          </Button>
        </div>
        <img className="right" src={require("@Assets/images/home/topRight.png")} alt="MMXN" />
      </div>
    </Wrapper>
  );
}

export default TopPannel;
