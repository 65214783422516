import styled from "styled-px2vw";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  background: #fff;
  > .inner {
    > #whymmxn {
      height: 156px;
      width: 100%;
    }
    width: 100%;
    padding: 0 50px 0;
    position: relative;
    overflow-y: hidden;
    > .left {
      align-items: flex-start;
      > .whyItem {
        &:nth-child(1) {
          margin-top: 0;
        }
        width: 500px;
        margin-top: 130px;
        > img {
          width: 203px;
          height: 140px;
        }
        > .title {
          font-size: 36px;
          font-family: CASaygonText-Medium, CASaygonText;
          font-weight: 500;
          color: #080f2a;
          line-height: 44px;
          font-weight: 500;
          margin: 40px 0 20px;
        }
        > .description {
          font-size: 24px;
          font-family: CASaygonText-Regular, CASaygonText;
          font-weight: 400;
          color: #080f2a;
          line-height: 36px;
          word-break: break-word;
          a {
            color: #00ec7d;
            &:hover {
              color: #39ffa2;
            }
          }
        }
      }
    }
    > .right {
      font-size: 54px;
      font-family: CASaygonText-Medium, CASaygonText;
      font-weight: 500;
      color: #080f2a;
      writing-mode: vertical-lr;
      position: absolute;
      top: 0;
      right: 50px;
      > .line {
        width: 1px;
        background: rgba(8, 15, 42, 0.3);
      }
      > p {
        margin: 48px 0 50px;
        text-transform: uppercase;
      }
      > :first-child {
        height: 251px;
      }
      > :last-child {
        height: 1000%;
        margin-bottom: -1000%;
      }
    }
  }
`;

const whyArr = [
  {
    img: "fiat.svg",
    title: "whymmxnTitle1",
    content: "whymmxnContent1",
  },
  {
    img: "compilant.svg",
    title: "whymmxnTitle2",
    content: "whymmxnContent2",
  },
  {
    img: "secure.svg",
    title: "whymmxnTitle3",
    content: "whymmxnContent3",
  },
  {
    img: "lowest.svg",
    title: "whymmxnTitle4",
    content: "whymmxnContent4",
  },
];

function WhyMmxn() {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <div className="inner">
        <div id="whymmxn" />
        <div className="left col-start">
          {whyArr.map(item => (
            <div className="whyItem" key={item.title}>
              <img src={require(`@Assets/images/home/${item.img}`)} alt="MMXN" />
              <p className="title">{t(item.title)}</p>
              <p
                className="description"
                dangerouslySetInnerHTML={{
                  __html: t(item.content),
                }}
              />
            </div>
          ))}
        </div>
        <div className="right row-center">
          <div className="line" />
          <p>{t("Why MMXN")}</p>
          <div className="line" />
        </div>
      </div>
    </Wrapper>
  );
}

export default WhyMmxn;
