import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  background: #080f2a;
  width: fit-content;
  min-width: 100%;
  padding: 88px 120px 100px;
  > .inner {
    width: 100%;
    max-width: 1400px;
    min-width: 1200px;
    margin: 0 auto;
    text-align: center;
    > :first-child {
      font-size: 48px;
      font-family: CASaygonText-Medium, CASaygonText;
      font-weight: 500;
      color: #fff;
    }
    > .subTitle {
      font-size: 16px;
      font-family: CASaygonText-Regular, CASaygonText;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.6);
      padding: 20px 0 80px;
    }
    > .partner {
      flex-wrap: wrap;
      justify-content: center;
      > img {
        cursor: pointer;
        margin: 0 32px 40px 0;
        width: 170px;
      }
    }
  }
`;

function Partner() {
  const { t } = useTranslation();
  const openLink = (href: string, target: string = "blank") => {
    window.open(href, target);
  };

  return (
    <Wrapper>
      <div className="inner">
        <p>Partners</p>
        <p className="subTitle">{t("build with partners")}</p>
        <div className="row-between partner">
          <img
            src={require("@Assets/images/home/trubit.png")}
            alt="mexo"
            onClick={() => {
              openLink("https://www.trubit.com/");
            }}
          />
          <img
            src={require("@Assets/images/home/certik.png")}
            alt="certik"
            onClick={() => {
              openLink("https://www.certik.org/");
            }}
          />
          <img
            src={require("@Assets/images/home/armanino.png")}
            alt="armanino"
            onClick={() => {
              openLink("https://www.armaninollp.com/");
            }}
          />
          <img
            src={require("@Assets/images/home/deltec.png")}
            alt="deltec"
            onClick={() => {
              openLink("https://www.deltecbank.com/digital-assets/?locale=en");
            }}
          />
          <img
            src={require("@Assets/images/home/delchain.png")}
            alt="delchain"
            onClick={() => {
              openLink("https://delchain.io/");
            }}
          />
          <img
            src={require("@Assets/images/home/kelman.png")}
            alt="kelman"
            onClick={() => {
              openLink("https://kelman.law/");
            }}
          />
          <img
            src={require("@Assets/images/home/krypital.png")}
            alt="krypital"
            onClick={() => {
              openLink("https://www.krypital.com/");
            }}
          />
          <img
            src={require("@Assets/images/home/mountain.png")}
            alt="mathwallet"
            onClick={() => {
              openLink("https://mathwallet.org/en-us/");
            }}
          />
          <img
            src={require("@Assets/images/home/blocto.png")}
            alt="blocto"
            onClick={() => {
              openLink("https://blocto.portto.io/en/");
            }}
          />
          <img
            src={require("@Assets/images/home/metamask.png")}
            alt="metamask"
            onClick={() => {
              openLink("https://metamask.io/");
            }}
          />
        </div>
      </div>
    </Wrapper>
  );
}

export default Partner;
