import { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Swiper from "swiper";
import "swiper/swiper-bundle.css";
import EN from "@/config/en";
import ES from "@/config/es";

const Wrapper = styled.div`
  padding: 20px 120px 0 120px;
  min-width: 100%;
  background: #080f2a;
  > .inner {
    position: relative;
    margin: 0 auto;
    width: 1200px;
    height: 750px;
    background-image: url(${require("@Assets/images/quotes/quotes_bg.png")});
    background-position: center;
    background-size: 1200px 750px;
    background-repeat: no-repeat;
    > .left,
    .right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 54px;
      height: 54px;
      border: 1px solid #ffffff;
      border-radius: 50%;
      cursor: pointer;
      user-select: none;
      z-index: 100;
      transition: all 0.1s ease-out;
      i {
        color: #ffffff;
        font-size: 30px;
        transition: all 0.1s ease-out;
      }
      &:hover {
        background: #ffffff;
        i {
          color: #080f2a;
        }
      }
    }
    > .left {
      left: 0;
    }
    > .right {
      right: 0;
    }
    .swiper-slide {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 750px;
      color: #ffffff;
      h4 {
        margin-top: 60px;
        margin-bottom: 89px;
        font-size: 48px;
        font-family: CASaygonText-Medium, CASaygonText;
        font-weight: 500;
        color: #ffffff;
        line-height: 58px;
      }
      > .line {
        width: 80px;
        height: 5px;
        background: #00ec7d;
      }
      p {
        margin: 85px 0 80px 0;
        width: 700px;
        text-align: center;
        font-size: 24px;
        font-family: CASaygonText-Regular, CASaygonText;
        font-weight: 400;
        color: #ffffff;
        line-height: 36px;
      }
      img {
        width: 300px;
        height: 80px;
      }
    }
  }
`;

interface IQuotes {
  text: string;
  logo: string;
}

function Quotes() {
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const domRef = useRef<HTMLDivElement>(null);
  const swiperRef = useRef<Swiper>();

  const [array, setArray] = useState<IQuotes[]>([]);

  useEffect(() => {
    if (array.length === 0) return undefined;
    const mySwiper = new Swiper(domRef.current!, {
      slidesPerView: "auto",
      loop: true,
    });
    swiperRef.current = mySwiper;
    return () => swiperRef.current!.destroy(true, true);
  }, [array]);

  useEffect(() => {
    const Lang = language === "en-us" ? EN : ES;
    setArray(Lang);
  }, [language]);

  return (
    <Wrapper>
      <div className="inner">
        <div className="left" onClick={() => swiperRef.current!.slidePrev()}>
          <i className="iconfont iconleft" />
        </div>
        <div className="swiper-container" ref={domRef}>
          <div className="swiper-wrapper">
            {array.map((item, index) => (
              <div key={index} className="swiper-slide">
                <h4>{t("Media")}</h4>
                <div className="line" />
                <p>{item.text}</p>
                <img src={item.logo} alt="logo" />
              </div>
            ))}
          </div>
        </div>
        <div className="right" onClick={() => swiperRef.current!.slideNext()}>
          <i className="iconfont iconright" />
        </div>
      </div>
    </Wrapper>
  );
}

export default Quotes;
