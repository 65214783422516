import Axios, { AxiosRequestConfig, AxiosResponse } from "axios";

export interface CustomResponse {
  readonly status: boolean;
  readonly message: string;
  data: any;
}
const Request = Axios.create({
  withCredentials: process.env.NODE_ENV === "production",
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Accept-Language": localStorage.getItem("lang") || "en-us",
  },
});

Request.interceptors.request.use((config: AxiosRequestConfig) => config);
Request.interceptors.response.use((response: AxiosResponse) => response);

export default Request;
