import { ButtonHTMLAttributes, ReactNode, FC } from "react";
import styled from "styled-components";

const Wrapper = styled.button`
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  /* border: none; */
  border-radius: 0;
  white-space: nowrap;
  -webkit-appearance: none;
  cursor: pointer;
  user-select: none;
  width: 200px;
  height: 64px;
  border: 1px solid ${props => (props.theme === "dark" ? "#080f2a" : "#ffffff")};
  background: transparent;
  font-size: 16px;
  font-family: CASaygonText-Regular, CASaygonText;
  font-weight: 400;
  color: ${props => (props.theme === "dark" ? "#080f2a" : "#ffffff")};
  transform: translate(0px, 0px);
  transition: color 0.4s cubic-bezier(0.65, 0, 0.35, 1);
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${props => (props.theme === "dark" ? "#080f2a" : "#ffffff")};
    transform: scaleY(0) translateY(0);
    left: 0;
    top: 0;
    pointer-events: none;
    z-index: -1;
    transform-origin: top center;
    transition: transform 0.4s cubic-bezier(0.65, 0, 0.35, 1);
  }
  &:focus {
    outline: none;
  }
  &:hover {
    color: ${props => (props.theme === "dark" ? "#ffffff" : "#080f2a")};
  }
  &:hover:after {
    transform: scaleY(1) translateY(0);
    transform-origin: bottom center;
  }
`;

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  theme?: "dark" | "light";
  children: ReactNode;
  onClick?: () => void;
}

const Button: FC<ButtonProps> = (props: ButtonProps) => {
  const { theme, children, onClick, ...otherProps } = props;

  return (
    <Wrapper {...otherProps} className="x-button" theme={theme} onClick={onClick}>
      {children}
    </Wrapper>
  );
};

export default Button;
