import styled from "styled-components";
import Header from "@Components/Header";
import Footer from "@Components/Footer";

interface Iprops {
  children: React.ReactNode;
}

const Wrapper = styled.div`
  min-width: 1440px;
  background: linear-gradient(118deg, #080f2a 0%, #151f44 100%);
`;

function RootLayout(props: Iprops) {
  const { children } = props;
  return (
    <Wrapper>
      <Header />
      <main>{children}</main>
      <Footer />
    </Wrapper>
  );
}

export default RootLayout;
