import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  background: #fff;
  min-width: 100%;
  width: fit-content;
  overflow-x: hidden;
  padding: 0 120px 0;
  > .inner {
    padding-top: 116px;
    max-width: 1400px;
    min-width: 1200px;
    margin: 0 auto;
    position: relative;
    align-items: flex-start;
    > .left {
      flex-wrap: wrap;
      align-items: baseline;
      width: 1080px;
      > .whyItem {
        width: 500px;
        margin-bottom: 100px;
        word-break: break-all;
        > img {
          width: 145px;
          height: 100px;
        }
        > .title {
          font-size: 22px;
          font-family: CASaygonText-Medium, CASaygonText;
          color: #080f2a;
          line-height: 28px;
          margin: 36px 0 24px;
        }
        > .description {
          font-size: 14px;
          font-family: CASaygonText-Regular, CASaygonText;
          color: #080f2a;
          line-height: 24px;
          word-break: break-word;
          a {
            color: #00ec7d;
            &:hover {
              color: #39ffa2;
            }
          }
        }
      }
    }
    > .right {
      font-size: 48px;
      font-family: CASaygonText-Medium, CASaygonText;
      color: #080f2a;
      writing-mode: vertical-rl;
      position: absolute;
      top: 0;
      right: 0;
      > .line {
        width: 1px;
        background: #080f2a;
      }
      > p {
        margin: 48px 0 50px;
        text-transform: uppercase;
      }
    }
  }
`;

const whyArr = [
  {
    img: "fiat.svg",
    title: "whymmxnTitle1",
    content: "whymmxnContent1",
  },
  {
    img: "compilant.svg",
    title: "whymmxnTitle2",
    content: "whymmxnContent2",
  },
  {
    img: "secure.svg",
    title: "whymmxnTitle3",
    content: "whymmxnContent3",
  },
  {
    img: "lowest.svg",
    title: "whymmxnTitle4",
    content: "whymmxnContent4",
  },
];

function WhyMmxn() {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <div className="inner row-between" id="whymmxn">
        <div className="left row-between">
          {whyArr.map(item => (
            <div className="whyItem" key={item.title}>
              <img src={require(`@Assets/images/home/${item.img}`)} alt="MMXN" />
              <p className="title">{t(item.title)}</p>
              <p
                className="description"
                dangerouslySetInnerHTML={{
                  __html: t(item.content),
                }}
              />
            </div>
          ))}
        </div>
        <div className="right row-center">
          <div className="line" style={{ height: 74 }} />
          <p>{t("Why MMXN")}</p>
          <div className="line" style={{ height: 148 }} />
        </div>
      </div>
    </Wrapper>
  );
}

export default WhyMmxn;
