import { useState, useEffect } from "react";
// import { useState, useEffect, useRef } from "react";
// import classnames from "classnames";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Button from "@Components/Button";

interface Props {
  scribe: boolean;
  scroll: boolean;
}

const Wrapper = styled.header<Props>`
  position: sticky;
  top: 0;
  z-index: 101;
  width: 100%;
  pointer-events: ${props => (props.scribe && props.scroll ? "none" : "auto")};
  > .inner {
    padding: 0 120px;
    background: ${props => (props.scribe ? "#00ec7d" : "transparent")};
    transition: background-color 0.4s ease-in-out;
    &::before {
      display: block;
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 6px;
      background: #00ec7d;
      transition: all 0.4s ease-in-out;
    }
    > .inside {
      margin: 0 auto;
      max-width: 1400px;
      height: 112px;
      transition: all 0.4s ease-in-out;
      > .logo {
        width: 175px;
        height: 32px;
        cursor: pointer;
        user-select: none;
        transition: all 0.4s ease-in-out;
      }
      > .right {
        height: 64px;
        a {
          position: relative;
          margin-right: 50px;
          line-height: 64px;
          font-size: 14px;
          font-family: CASaygonText-Regular, CASaygonText;
          font-weight: 400;
          color: ${props => (props.scribe ? "#080f2a" : "#ffffff")};
          transition: all 0.4s ease-in-out;
          &:after {
            display: block;
            content: "";
            position: absolute;
            bottom: 10px;
            left: 50%;
            width: 100%;
            height: 3px;
            background: ${props => (props.scribe ? "#080f2a" : "#00ec7d")};
            transform: translateX(-50%) scaleX(0);
            transition: all 0.4s ease-in-out;
          }
          &:hover {
            color: ${props => (props.scribe ? "#080f2a" : "#00ec7d")};
            &:after {
              transform: translate(-50%) scaleX(1);
            }
          }
        }
        > .switch {
          margin-left: 50px;
          width: 64px;
          height: 64px;
          span {
            font-size: 14px;
            font-family: CASaygonText-Regular, CASaygonText;
            color: ${props => (props.scribe ? "#080f2a" : "#ffffff")};
            line-height: 17px;
            user-select: none;
          }
          span:nth-child(1),
          span:nth-child(3) {
            cursor: pointer;
            transition: all 0.4s ease-in-out;
            &:hover {
              color: ${props => (props.scribe ? "#080f2a" : "#00ec7d")};
            }
          }
          span:nth-child(2) {
            margin: 0 10px;
          }
        }
        > .active {
          span:nth-child(1) {
            font-family: CASaygonText-Bold, CASaygonText;
            color: ${props => (props.scribe ? "#080f2a" : "#00ec7d")};
          }
        }
        > .default {
          span:nth-child(3) {
            font-family: CASaygonText-Bold, CASaygonText;
            color: ${props => (props.scribe ? "#080f2a" : "#00ec7d")};
          }
        }
      }
    }
  }
  > .fall {
    animation: smoothScroll 0.4s forwards;
    @keyframes smoothScroll {
      0% {
        transform: translateY(-100%);
      }
      100% {
        transform: translateY(0px);
      }
    }
  }
  > .rising {
    animation: smoothScrollTop 0.4s forwards;
    @keyframes smoothScrollTop {
      0% {
        transform: translateY(0px);
      }
      100% {
        transform: translateY(-100%);
      }
    }
  }
`;

const navList = [
  {
    name: "About MMXN",
    path: "/about",
  },
  {
    name: "Blog",
    path: "https://monetadigital.medium.com/",
  },
  {
    name: "FAQ",
    path: "/faq",
  },
];

function Header() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const currentLang = localStorage.lang === "en-us" ? "en-us" : "es-es";

  const [scollType, setScollType] = useState<boolean>(false);
  const [scribeType, setScribeType] = useState<boolean>(false);

  // 页面滚动高度
  const getScrollTop = () => {
    let scrollTop = 0;
    if (document.documentElement && document.documentElement.scrollTop) {
      scrollTop = document.documentElement.scrollTop;
    } else if (document.body) {
      scrollTop = document.body.scrollTop;
    }
    return scrollTop;
  };

  // 页面滚动方式
  const getScollType = () => {
    let beforeScollh = 0;
    window.addEventListener("scroll", () => {
      const afterScollh = getScrollTop();
      const differH = afterScollh - beforeScollh;
      if (differH === 0) return;
      // const scollType = differH > 0 ? 'down' : 'up';
      const scollTypeInner = differH > 0;
      const scribeTypeInner = afterScollh >= 112;
      setScollType(scollTypeInner);
      setScribeType(scribeTypeInner);
      // console.log(afterScollh);
      beforeScollh = afterScollh;
      // console.log("type", beforeScollh, afterScollh, scollType);
    });
  };

  useEffect(() => {
    getScollType();
    // return () => getScollType();
  }, []);

  const changeLocale = (lan: string): void => {
    i18n.changeLanguage(lan);
    localStorage.setItem("lang", lan);
  };

  return (
    <Wrapper className="x-header" scribe={scribeType} scroll={scollType}>
      <div className={`inner ${scribeType && scollType ? "rising" : "fall"}`}>
        <div className="inside row-between">
          <img
            className="logo"
            src={require(`@Assets/images/header/${scribeType ? "active" : ""}logo.svg`)}
            alt="logo"
            onClick={() => {
              history.push("/");
            }}
          />
          <nav className="right row-start">
            {navList.map(item => (
              <a key={item.name} href={item.path}>
                {t(item.name)}
              </a>
            ))}
            <Button
              theme={scribeType ? "dark" : "light"}
              onClick={() => {
                window.open("https://www.trubit.com/otc/trade/MMXN", "_blank");
              }}
            >
              {t("Get MMXN Button")}
            </Button>
            <p className={`switch row-center ${currentLang === "es-es" ? "active" : "default"}`}>
              <span onClick={() => changeLocale("es-es")}>ES</span>
              <span> | </span>
              <span onClick={() => changeLocale("en-us")}>EN</span>
            </p>
          </nav>
        </div>
      </div>
    </Wrapper>
  );
}

export default Header;
