import { FC } from "react";
import { RouteConfig } from "react-router-config";
import { Route, Redirect, Switch } from "react-router-dom";
import { pathToRegexp } from "path-to-regexp";
import { RouteModel } from "./router.config";

export const isPc = (): boolean => {
  const userAgentInfo = window.navigator.userAgent.toLowerCase();
  const Agents = ["android", "iphone", "symbianOS", "windows phone", "ipad", "ipod"];
  let flag = true;
  for (let v = 0; v < Agents.length; v += 1) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false;
      break;
    }
  }
  return flag;
};

function LoginHandler({ targetRoute }: any): any {
  const { path } = targetRoute;
  // if (path === "/login" || path === "/m/login") {
  //   return <Redirect to="/" />;
  // }
  if (path.startsWith("/mobile") && isPc()) {
    return (
      <Switch>
        <Redirect from={path} to={path.replace(/^\/mobile/, "")} />
        <Route path={path.replace(/^\/mobile/, "")} />
      </Switch>
    );
  }
  if (!path.startsWith("/mobile") && !isPc()) {
    return (
      <Switch>
        <Redirect from={path} to={`/mobile${path}`} />
        <Route path={`/mobile${path}`} />
      </Switch>
    );
  }
  return <Route path={path} render={innerProps => <targetRoute.component {...innerProps} route={{ routes: targetRoute.routes }} />} />;
}

function NotLoginHandler(props: any): any {
  const { targetRoute } = props;
  const { auth } = targetRoute;

  if (auth) return <Redirect to="/login" />;

  return (
    <Route
      path={targetRoute.path}
      render={innerProps => <targetRoute.component {...innerProps} route={{ routes: targetRoute.routes }} />}
    />
  );
}

export interface NavigationGuardsProps extends RouteConfig {
  routeConfig: RouteModel[];
}

const RouterGuard: FC<NavigationGuardsProps> = props => {
  const { location, routeConfig } = props;
  const switchRoute = (pathConfig: string, pathTarget: string) => {
    if (pathConfig === pathTarget) return true;
    const re = pathToRegexp(pathConfig);

    return re.exec(pathTarget);
  };

  const targetPath: string | undefined = location?.pathname;
  const targetRoute: RouteModel | undefined = routeConfig.find(item => targetPath && switchRoute(item.path, targetPath));
  const isLogin = true; // TODO: 管理登陆状态

  if (!targetRoute) return <div>404</div>;

  if (isLogin) {
    return <LoginHandler targetRoute={targetRoute} />;
  }
  return <NotLoginHandler targetRoute={targetRoute} />;
};

export default RouterGuard;
