import RootLayout from "@Layout/RootLayout";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  > .top {
    height: 580px;
    background-image: url(${require("@Assets/images/about/bg.png")});
    background-position: right bottom;
    background-repeat: no-repeat;
    padding: 0 120px;
    > .topInner {
      height: 580px;
      margin: 0 auto;
      width: 100%;
      max-width: 1400px;
      min-width: 1200px;
      > :first-child {
        width: 540px;
        > .title {
          font-size: 66px;
          font-family: CASaygonText-Bold, CASaygonText;
          color: #00ec7d;
          line-height: 79px;
          white-space: nowrap;
        }
        > p {
          word-break: break-word;
          color: #fff;
          font-size: 16px;
          font-family: CASaygonText-Regular, CASaygonText;
          font-weight: 400;
          line-height: 24px;
          margin-top: 32px;
        }
      }
      > img {
        height: 300px;
        width: auto;
        /* width: 701px; */
      }
    }
  }
  > .bottom {
    min-width: 100%;
    width: fit-content;
    background: #fff;
    position: relative;
    overflow: hidden;
    padding: 0 120px;
    > .content {
      max-width: 1400px;
      min-width: 1200px;
      width: 100%;
      margin: 0 auto;
      position: relative;
      .green {
        position: absolute;
        top: 0;
        right: 0;
        width: 281px;
        height: 188px;
        background: #00ec7d;
        z-index: 0;
      }
      .ourPromise {
        position: relative;
        z-index: 2;
        padding: 68px 0 34px;
        > p {
          width: 500px;
          font-size: 16px;
          font-family: CASaygonText-Regular, CASaygonText;
          font-weight: 400;
          color: #080f2a;
          line-height: 24px;
        }
      }
      .ourStyle {
        align-items: flex-start;
        .styleItem {
          > img {
            width: 135px;
            height: 90px;
          }
          > h4 {
            font-size: 48px;
            font-family: CASaygonText-Medium, CASaygonText;
            font-weight: 500;
            color: #080f2a;
            margin: 40px 0 30px;
            width: fit-content;
            position: relative;
            > .line {
              margin-top: 15px;
              height: 1px;
              background: rgba(8, 15, 42, 0.4);
              z-index: 2;
            }
          }
          > .des {
            font-size: 16px;
            font-family: CASaygonText-Regular, CASaygonText;
            font-weight: 400;
            color: #080f2a;
            line-height: 24px;
          }
        }
        .missionOuter {
          position: relative;
          width: 760px;
          height: 658px;
          background: url(${require("@Assets/images/about/fillbg.svg")}) no-repeat;
          background-size: cover;
          .mission {
            position: absolute;
            bottom: 22%;
            left: 20%;
            > img {
              width: 151;
              height: 70px;
            }
            > .des {
              width: 500px;
            }
          }
        }
      }
    }
  }
`;

function AboutPage() {
  const { t } = useTranslation();
  return (
    <RootLayout>
      <Wrapper>
        <div className="top">
          <div className="topInner row-between">
            <div>
              <h2 className="title">{t("About Moneta")}</h2>
              <p>{t("about1")}</p>
              <p>{t("about1.1")}</p>
            </div>
            <img src={require("@Assets/images/about/earth.svg")} alt="earth" />
          </div>
        </div>
        <div className="bottom">
          <div className="content">
            <div className="green" />
            <div className="row-end ourPromise">
              <p className="">{t("about2")}</p>
            </div>
            <div className="ourStyle row-between">
              <div className="styleItem">
                <img src={require("@Assets/images/about/vision.svg")} alt="vision" />
                <h4>
                  {t("Our Vision")}
                  <div className="line" style={{ width: "1000%", marginLeft: "-1000%" }} />
                  <div className="line" style={{ width: "100%", margin: 0, transform: "translateY(-1px)" }} />
                </h4>
                <p className="des">{t("vision text")}</p>
              </div>
              <div className="missionOuter">
                <div className="styleItem mission">
                  <img src={require("@Assets/images/about/mission.svg")} alt="vision" />
                  <h4>
                    {t("Our Mission")}
                    <div className="line" style={{ width: "1000%", marginRight: "-1000%" }} />
                  </h4>
                  <p className="des">{t("mission content")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </RootLayout>
  );
}

export default AboutPage;
