import MobileLayout from "@Layout/MobileLayout";
import TopPannel from "./child/TopPannel";
import TradeMmxn from "./child/TradeMmxn";
import WhyMmxn from "./child/WhyMmxn";
import GetMmxn from "./child/GetMmxn";
import UseCase from "./child/UseCase";
import Partner from "./child/Partner";
import Quotes from "./child/Quotes";
import Contact from "./child/Contact";

function HomePage() {
  return (
    <MobileLayout>
      <TopPannel />
      <TradeMmxn />
      <WhyMmxn />
      <GetMmxn />
      <UseCase />
      <Partner />
      <Quotes />
      <Contact />
    </MobileLayout>
  );
}

export default HomePage;
