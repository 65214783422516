import Button from "@Components/Button";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Wrapper = styled.footer`
  background: #00ec7d;
  min-width: 100%;
  width: fit-content;
  padding: 74px 120px 40px;
  > .inner {
    margin: 0 auto;
    width: 100%;
    max-width: 1400px;
    min-width: 1200px;
    margin: 0 auto;
    > .top {
      img {
        width: 51px;
        height: 44px;
      }
      p {
        margin-left: 21px;
        width: 260px;
        font-size: 16px;
        font-family: CASaygonText-Regular, CASaygonText;
        font-weight: 400;
        color: #080f2a;
        line-height: 20px;
      }
    }
    > .bottom {
      margin-top: 40px;
      align-items: flex-start;
      > .bottomItem {
        align-items: flex-start;
        height: 500px;
        > .bring {
          h2 {
            margin-bottom: 40px;
            width: 439px;
            font-size: 48px;
            font-family: CASaygonText-Regular, CASaygonText;
            font-weight: 400;
            color: #080f2a;
            line-height: 58px;
          }
        }
        > .address {
          padding-bottom: 10px;
          font-size: 12px;
          font-family: CASaygonText-Regular, CASaygonText;
          font-weight: 400;
          color: #080f2a;
          line-height: 15px;
        }
        > ul {
          margin-top: 5px;
          align-items: flex-start;
          li {
            h4 {
              margin-bottom: 24px;
              font-size: 16px;
              font-family: CASaygonText-Bold, CASaygonText;
              color: #080f2a;
              line-height: 20px;
            }
            p {
              font-size: 14px;
              font-family: CASaygonText-Regular, CASaygonText;
              font-weight: 400;
              color: rgba(8, 15, 42, 0.7);
              line-height: 17px;
              cursor: pointer;
              user-select: none;
              transition: color 0.2s ease-in-out;
              &:hover {
                color: #080f2a;
                font-weight: 600;
              }
            }
            p + p {
              margin-top: 10px;
            }
          }
          li + li {
            margin-left: 80px;
          }
        }
        > .icon {
          i {
            color: #080f2a;
            font-size: 36px;
            cursor: pointer;
            user-select: none;
            transition: all 0.3s ease-in-out;
            &:hover {
              color: #057d54;
            }
          }
        }
      }
    }
  }
`;

function Footer() {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <div className="inner">
        <div className="top row-start">
          <img src={require("@Assets/images/footer/logo.svg")} alt="logo" />
          <p>{t("footerTitle")}</p>
        </div>
        <div className="bottom row-between">
          <div className="bottomItem col-between">
            <div className="bring">
              <h2>{t("bring")}</h2>
              <Button
                theme="dark"
                onClick={() => {
                  window.open("https://www.trubit.com/otc/trade/MMXN", "_blank");
                }}
              >
                {t("Get MMXN Button")}
              </Button>
            </div>
            <p className="address">Copyright © {new Date().getFullYear()} Moneta Digital.</p>
          </div>
          <div className="bottomItem col-between">
            <ul className="row-start">
              <li>
                <h4>{t("About")}</h4>
                <p onClick={() => window.open("/about", "_self")}>{t("About Moneta")}</p>
                <p
                  onClick={() => {
                    window.open("/#whymmxn", "_blank");
                  }}
                >
                  {t("Why MMXN Footer")}
                </p>
                <p
                  onClick={() => {
                    window.open("https://www.trubit.com/otc/trade/MMXN", "_blank");
                  }}
                >
                  {t("Get MMXN Footer")}
                </p>
                <p onClick={() => window.open("/faq", "_self")}>{t("FAQ")}</p>
                <p onClick={() => window.open("https://github.com/moneta-digital/mmxn-specs", "_blank")}>{t("Whitepaper")}</p>
              </li>
              <li>
                <h4>{t("Legal")}</h4>
                <p onClick={() => window.open("/legal/privacy", "_self")}>{t("Privacy")}</p>
                <p onClick={() => window.open("/legal/terms", "_self")}>{t("Terms")}</p>
                {/* <p>{t("Attestation")}</p> */}
              </li>
              <li>
                <h4>{t("Contact")}</h4>
                <p onClick={() => window.open("mailto:contact@monetadigital.com", "_blank")}>{t("General Information")}</p>
                <p onClick={() => window.open("mailto:career@monetadigital.com", "_blank")}>{t("Carrer")}</p>
                <p onClick={() => window.open("mailto:otc@monetadigital.com", "_blank")}>OTC</p>
              </li>
            </ul>
            <div className="icon row-start">
              <i className="iconfont icontwitter" onClick={() => window.open("https://twitter.com/MMXN_Moneta", "_blank")} />
              <i
                className="iconfont iconfacebook"
                onClick={() => window.open("https://www.facebook.com/Moneta-Digital-100603312072551", "_blank")}
              />
              <i
                className="iconfont iconmedium"
                onClick={() => window.open("https://monetadigital.medium.com/", "_blank")}
                style={{ marginRight: 6 }}
              />
              <i
                className="iconfont iconlinkedIn"
                onClick={() => window.open("https://www.linkedin.com/company/mmxn-moneta-digital", "_blank")}
              />
              <i
                className="iconfont iconyoutube"
                onClick={() => window.open("https://www.youtube.com/channel/UCjJJ9k5tL2uVI-mDt6kZA3A", "_blank")}
                style={{ marginLeft: 4 }}
              />
              <i
                className="iconfont iconinstagram"
                onClick={() => window.open("https://www.instagram.com/monetadigitalmx/", "_blank")}
                style={{ marginLeft: 4 }}
              />
              <i
                className="iconfont icontelegram"
                onClick={() => window.open("https://t.me/Moneta_Digital", "_blank")}
                style={{ marginLeft: 4 }}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default Footer;
