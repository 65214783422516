import { BrowserRouter, Switch } from "react-router-dom";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import routes from "@Router/router.config";

import "@Assets/styles/global.less";

import EN from "@Locales/en-us.json";
import ES from "@Locales/es-es.json";
import RouterGuard from "@Router/Guards";

// const urlLang = new URLSearchParams(window.location.search).get("language");

// if (urlLang && urlLang !== localStorage.lang) {
//   localStorage.lang = urlLang;
// }

i18n.use(initReactI18next).init({
  lng: localStorage.lang === "en-us" ? "en-us" : "es-es",
  fallbackLng: localStorage.lang === "en-us" ? "en-us" : "es-es",
  resources: {
    "en-us": {
      translation: EN,
    },
    "es-es": {
      translation: ES,
    },
  },
  lowerCaseLng: true,
  interpolation: {
    escapeValue: false,
  },
});

const App = () => (
  <BrowserRouter>
    <Switch>
      <RouterGuard routeConfig={routes} />
    </Switch>
  </BrowserRouter>
);

export default App;
