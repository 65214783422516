import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  background: #fff;
  width: fit-content;
  min-width: 100%;
  padding: 0 120px 0;
  > .inner {
    width: 100%;
    max-width: 1400px;
    min-width: 1200px;
    height: 658px;
    margin: 0 auto;
    align-items: flex-start;
    > .left {
      align-items: flex-start;
      height: 100%;
      position: relative;
      > :first-child {
        > :first-child {
          font-size: 48px;
          font-family: CASaygonText-Medium, CASaygonText;
          font-weight: 500;
          color: #080f2a;
          padding: 20px 0 40px 0;
        }
        /* >:last-child {
          font-size: 16px;
          font-family: CASaygonText-Regular, CASaygonText;
          font-weight: 400;
          color: #080F2A;
          line-height: 24px;
          width: 320px;
        } */
      }
      > .line {
        position: absolute;
        bottom: 0;
        width: 1px;
        height: 76%;
        background: rgba(8, 15, 42, 0.6);
        left: 26%;
      }
    }
    > .right {
      padding: 72px 60px 0 60px;
      height: 100%;
      width: 841px;
      background: #f7f7f7;
      flex-wrap: wrap;
      align-items: flex-start;
      > div {
        width: 33%;
        min-height: 180px;
        img {
          width: 135px;
          height: 100px;
        }
        p {
          /* white-space: nowrap; */
          margin-top: 40px;
          font-size: 16px;
          line-height: 20px;
          text-align: center;
          font-family: CASaygonText-Medium, CASaygonText;
          font-weight: 500;
          color: #080f2a;
        }
      }
    }
  }
`;

function UseCase() {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <div className="inner row-start">
        <div className="left col-between" style={{ flex: 1 }}>
          <div>
            <p>{t("Use Cases")}</p>
            {/* <p>
              {t("caseText")}
            </p> */}
          </div>
          <div className="line" />
        </div>
        <div className="right row-between">
          <div className="col-start">
            <img src={require("@Assets/images/home/medium.svg")} alt="MMXN" />
            <p>{t("cases1")}</p>
          </div>
          <div className="col-start">
            <img src={require("@Assets/images/home/financial.svg")} alt="MMXN" />
            <p>{t("cases2")}</p>
          </div>
          <div className="col-start">
            <img src={require("@Assets/images/home/remittances.svg")} alt="MMXN" />
            <p>{t("cases3")}</p>
          </div>
          <div className="col-start">
            <img src={require("@Assets/images/home/payments.svg")} alt="MMXN" />
            <p>{t("cases4")}</p>
          </div>
          <div className="col-start">
            <img src={require("@Assets/images/home/store.svg")} alt="MMXN" />
            <p>{t("cases5")}</p>
          </div>
          <div className="col-start">
            <img src={require("@Assets/images/home/dapp.svg")} alt="MMXN" />
            <p>{t("cases6")}</p>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default UseCase;
