import styled from "styled-px2vw";
import { useTranslation } from "react-i18next";

import Button from "@Components/Mobile/Button";

const Wrapper = styled.div`
  background: #fff;
  width: 100%;
  padding-bottom: 100px;
  padding-top: 120px;
  > .top {
    position: relative;
    align-items: flex-start;
    > .greenBg {
      width: 400px;
      height: 310px;
      background: #00ec7d;
      padding-left: 50px;
      padding-top: 78px;
      font-size: 54px;
      font-family: CASaygonText-Medium, CASaygonText;
      font-weight: 500;
      color: #080f2a;
    }
    > .line {
      position: absolute;
      width: 484px;
      height: 1px;
      background: rgba(8, 15, 42, 0.4);
      right: 0;
      bottom: 34px;
    }
    > :last-child {
      padding-right: 50px;
      width: 320px;
      padding-top: 78px;
      font-size: 24px;
      font-family: CASaygonText-Regular, CASaygonText;
      font-weight: 400;
      color: #080f2a;
      line-height: 36px;
    }
  }

  > .methods {
    padding: 60px 50px 0;
    overflow: hidden;
    li {
      position: relative;
      margin-bottom: 40px;
      border: 1px solid #0c1125;
      > p {
        font-weight: 500;
        font-size: 32px;
        line-height: 36px;
        color: #080f2a;
        padding: 36px 40px 42px;
      }
      .logo {
        margin: 0 0 48px 40px;
        img {
          height: 44px;
        }
        p {
          margin-left: 10px;
          font-weight: 500;
          font-size: 28px;
          color: #2c2b2b;
        }
      }
      > .green {
        height: 102%;
        width: 70px;
        background: #28e775;
        position: absolute;
        top: -2px;
        left: -60px;
        z-index: 2;
      }
    }
  }

  > .bottom {
    padding-right: 50px;
    padding-top: 22px;
    > .line {
      width: 354px;
      height: 1px;
      background: rgba(8, 15, 42, 0.4);
    }
    > .x-button {
      min-width: 320px;
      height: 88px;
      font-size: 24px;
      font-family: CASaygonText-Regular, CASaygonText;
      font-weight: 400;
    }
  }
`;

const arr: Array<{ img: string; name: string; link?: string; remark?: string }> = [
  {
    img: "bank.svg",
    name: "Bank Transfer Tips",
    remark: "Bank Transfer",
  },
  {
    img: "mercado.png",
    name: "with Mercado Pago",
  },
  {
    img: "banxa.png",
    name: "with Banxa",
  },
  {
    img: "trubitColor.png",
    name: "P2P Commerce in Trubit Pro",
    link: "https://www.trubit.com/otc/",
  },
];

function GetMmxn() {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <div className="top row-between">
        <div className="greenBg">{t("Buy and Sell MMXN")}</div>
        <div className="line" />
        <p>{t("Buy with bank")}</p>
      </div>

      <ul className="methods">
        {arr.map(item => (
          <li
            key={item.img}
            onClick={() => {
              if (item.link) {
                window.open(item.link, "_blank");
              }
            }}
          >
            <div className="green" />
            <p className="text">{t(item.name)}</p>
            <div className="logo row-start">
              <img src={require(`@Assets/images/home/${item.img}`)} alt="icon" />
              {item.remark && <p>{t(item.remark)}</p>}
            </div>
          </li>
        ))}
      </ul>

      <div className="bottom row-between">
        <div className="line" />
        <Button
          theme="dark"
          onClick={() => {
            window.open("https://www.trubit.com/otc/trade/MMXN", "_blank");
          }}
        >
          {t("Buy MMXN now")}
        </Button>
      </div>
    </Wrapper>
  );
}

export default GetMmxn;
