import { useRef, useState, useEffect } from "react";
import styled from "styled-px2vw";
import { useTranslation } from "react-i18next";
import classnames from "classnames";

import Button from "./Button";

const Wrapper = styled.footer`
  background: #00ec7d;
  padding: 64px 50px 90px;
  color: #080f2a;
  > .logo {
    height: 70px;
    width: auto;
    margin-bottom: 70px;
    + p {
      font-size: 24px;
      color: #080f2a;
      font-family: CASaygonText-Regular, CASaygonText;
      font-weight: 400;
      margin-bottom: 40px;
      line-height: 28px;
    }
  }
  > h2 {
    font-size: 48px;
    font-family: CASaygonText-Regular, CASaygonText;
    font-weight: 400;
    color: #080f2a;
    line-height: 66px;
  }
  > .buttonOuter {
    margin-top: 34px;
    margin-bottom: 100px;
    > button {
      width: 250px;
      height: 88px;
      font-size: 22px;
    }
  }
  > .hrefs {
    width: 100%;
    > .hrefItem {
      margin-bottom: 30px;
      > .itemTitle {
        margin-bottom: 30px;
        > :first-child {
          width: 24px;
          height: 24px;
          margin-right: 16px;
          color: #080f2a;
          font-size: 24px;
        }
        > :last-child {
          font-size: 28px;
          font-family: CASaygonText-Medium, CASaygonText;
          font-weight: 500;
          color: #080f2a;
          line-height: 33px;
        }
      }
      > .itemContent {
        overflow: hidden;
        transition: all 0.3s ease;
        > .itemInner > p {
          margin-bottom: 24px;
          font-size: 24px;
          font-family: CASaygonText-Medium, CASaygonText;
          font-weight: 400;
          line-height: 24px;
          margin-left: 40px;
        }
      }
    }
  }
  > .icon {
    > i {
      font-size: 54px;
    }
  }
  > .address {
    font-size: 24px;
    font-family: CASaygonText-Regular, CASaygonText;
    font-weight: 400;
    color: #080f2a;
    line-height: 28px;
    padding-top: 34px;
  }
`;

function Footer() {
  const { t } = useTranslation();
  const refEl = useRef(null as null | HTMLDivElement);
  const [heightList, setHeightList] = useState([]);
  const [open, setOpen] = useState(-1);
  useEffect(() => {
    const el: any = refEl.current?.getElementsByClassName("itemInner");
    const list = Array.from(el);
    const array: any = [];

    list.forEach((element: any) => {
      array.push(element.clientHeight);
    });
    setHeightList(array);
  }, []);
  return (
    <Wrapper>
      <img src={require("@Assets/images/header/activelogo.svg")} alt="logo" className="logo" />
      <h2>{t("bring")}</h2>
      <div className="buttonOuter">
        <Button
          theme="dark"
          onClick={() => {
            window.open("https://www.trubit.com/otc/trade/MMXN", "_blank");
          }}
        >
          {t("Get MMXN Button")}
        </Button>
      </div>
      <div className="hrefs" ref={refEl}>
        <div className="hrefItem">
          <div className="itemTitle row-start" onClick={() => setOpen(open === 0 ? -1 : 0)}>
            <i className={classnames("iconfont", open === 0 ? "iconminus" : "iconplus")} />
            <p>{t("About")}</p>
          </div>
          <div className="itemContent" style={{ height: open === 0 ? `${heightList[0]}px` : 0 }}>
            <div className="itemInner">
              <p onClick={() => window.open("/mobile/about", "_self")}>{t("About Moneta")}</p>
              <p onClick={() => window.open("/mobile/about", "_self")}>{t("Why MMXN Footer")}</p>
              <p
                onClick={() => {
                  window.open("https://www.trubit.com/otc/trade/MMXN", "_blank");
                }}
              >
                {t("Get MMXN Footer")}
              </p>
              <p onClick={() => window.open("/mobile/faq", "_self")}>{t("FAQ")}</p>
              <p onClick={() => window.open("https://github.com/moneta-digital/mmxn-specs", "_blank")}>{t("Whitepaper")}</p>
            </div>
          </div>
        </div>
        <div className="hrefItem">
          <div className="itemTitle row-start" onClick={() => setOpen(open === 1 ? -1 : 1)}>
            <i className={classnames("iconfont", open === 1 ? "iconminus" : "iconplus")} />
            <p>{t("Legal")}</p>
          </div>
          <div className="itemContent" style={{ height: open === 1 ? `${heightList[1]}px` : 0 }}>
            <div className="itemInner">
              <p onClick={() => window.open("/mobile/legal/privacy", "_self")}>{t("Privacy")}</p>
              <p onClick={() => window.open("/mobile/legal/terms", "_self")}>{t("Terms")}</p>
              {/* <p>{t("Attestation")}</p> */}
            </div>
          </div>
        </div>
        <div className="hrefItem">
          <div className="itemTitle row-start" onClick={() => setOpen(open === 2 ? -1 : 2)}>
            <i className={classnames("iconfont", open === 2 ? "iconminus" : "iconplus")} />
            <p>{t("Contact")}</p>
          </div>
          <div className="itemContent" style={{ height: open === 2 ? `${heightList[2]}px` : 0 }}>
            <div className="itemInner">
              <p onClick={() => window.open("mailto:contact@monetadigital.com", "_blank")}>{t("General Information")}</p>
              <p onClick={() => window.open("mailto:career@monetadigital.com", "_blank")}>{t("Carrer")}</p>
              <p onClick={() => window.open("mailto:otc@monetadigital.com", "_blank")}>OTC</p>
            </div>
          </div>
        </div>
      </div>
      <div className="icon row-start">
        <i className="iconfont icontwitter" onClick={() => window.open("https://twitter.com/MMXN_Moneta", "_blank")} />
        <i
          className="iconfont iconfacebook"
          onClick={() => window.open("https://www.facebook.com/Moneta-Digital-100603312072551", "_blank")}
        />
        <i
          className="iconfont iconmedium"
          onClick={() => window.open("https://monetadigital.medium.com/", "_blank")}
          style={{ marginRight: 4 }}
        />
        <i
          className="iconfont iconlinkedIn"
          onClick={() => window.open("https://www.linkedin.com/company/mmxn-moneta-digital", "_blank")}
        />
        <i
          className="iconfont iconyoutube"
          onClick={() => window.open("https://www.youtube.com/channel/UCjJJ9k5tL2uVI-mDt6kZA3A", "_blank")}
          style={{ marginLeft: 4 }}
        />
        <i
          className="iconfont iconinstagram"
          onClick={() => window.open("https://www.instagram.com/monetadigitalmx/", "_blank")}
          style={{ marginLeft: 4 }}
        />
        <i
          className="iconfont icontelegram"
          onClick={() => window.open("https://t.me/Moneta_Digital", "_blank")}
          style={{ marginLeft: 4 }}
        />
      </div>
      <p className="address">Copyright © {new Date().getFullYear()} Moneta Digital.</p>
    </Wrapper>
  );
}

export default Footer;
