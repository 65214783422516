import { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import RootLayout from "@Layout/RootLayout";

const Wrapper = styled.div`
  > .top {
    width: 100%;
    height: 540px;
    background-image: url(${require("@Assets/images/home/topBg.png")});
    background-position: right bottom;
    background-repeat: no-repeat;
    padding: 0 120px;
    > .topInner {
      max-width: 1400px;
      min-width: 1200px;
      margin: 0 auto;
      height: 540px;
      width: 100%;
      > .title {
        width: 720px;
        font-size: 66px;
        font-family: CASaygonText-Bold, CASaygonText;
        color: #00ec7d;
        line-height: 79px;
      }
      > .right {
        width: 628px;
        /* height: 324px; */
      }
    }
  }
  > .content {
    padding: 82px 120px 60px;
    background: #fff;
    min-width: 100%;
    width: fit-content;
    > .inner {
      width: 100%;
      max-width: 1400px;
      min-width: 1200px;
      margin: 0 auto;
      > .faqItem {
        > .itemTitle {
          margin-bottom: 22px;
          > :first-child {
            width: 24px;
            height: 24px;
            background: #eaeaea;
            border-radius: 1px;
            cursor: pointer;
            margin-right: 16px;
            > i {
              color: #727272;
              font-size: 18px;
            }
          }
          > :last-child {
            cursor: pointer;
            font-size: 16px;
            font-family: CASaygonText-Medium, CASaygonText;
            font-weight: 500;
            color: #080f2a;
            line-height: 20px;
          }
        }
        > .itemContent {
          overflow: hidden;
          margin-bottom: 22px;
          font-size: 14px;
          font-family: CASaygonText-Medium, CASaygonText;
          font-weight: 500;
          color: #535353;
          line-height: 24px;
          margin-left: 40px;
          word-break: break-word;
          transition: all 0.3s ease;
          > .itemInner {
            ul li::before {
              content: "• ";
            }
            a {
              color: #08a65c;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
`;

const arr: {
  title: string;
  content: string;
}[] = [
  {
    title: "faqTitle1",
    content: "faqContent1",
  },
  {
    title: "faqTitle2",
    content: "faqContent2",
  },
  {
    title: "faqTitle3",
    content: "faqContent3",
  },
  {
    title: "faqTitle4",
    content: "faqContent4",
  },
  {
    title: "faqTitle5",
    content: "faqContent5",
  },
  {
    title: "faqTitle6",
    content: "faqContent6",
  },
  {
    title: "faqTitle7",
    content: "faqContent7",
  },
  {
    title: "faqTitle8",
    content: "faqContent8",
  },
  {
    title: "faqTitle9",
    content: "faqContent9",
  },
  {
    title: "faqTitle10",
    content: "faqContent10",
  },
  {
    title: "faqTitle11",
    content: "faqContent11",
  },
  {
    title: "faqTitle12",
    content: "faqContent12",
  },
  {
    title: "faqTitle13",
    content: "faqContent13",
  },
  {
    title: "faqTitle14",
    content: "faqContent14",
  },
  {
    title: "faqTitle15",
    content: "faqContent15",
  },
  {
    title: "faqTitle16",
    content: "faqContent16",
  },
  {
    title: "faqTitle17",
    content: "faqContent17",
  },
  {
    title: "faqTitle18",
    content: "faqContent18",
  },
  {
    title: "faqTitle19",
    content: "faqContent19",
  },
  {
    title: "faqTitle20",
    content: "faqContent20",
  },
  {
    title: "faqTitle22",
    content: "faqContent22",
  },
  {
    title: "faqTitle23",
    content: "faqContent23",
  },
];

function FAQPage() {
  const { t, i18n } = useTranslation();

  const refEl = useRef(null as null | HTMLDivElement);
  const [heightList, setHeightList] = useState([]);
  const [open, setOpen] = useState(-1);
  useEffect(() => {
    setTimeout(() => {
      const el: any = refEl.current?.getElementsByClassName("itemInner");
      const list = Array.from(el);
      const array: any = [];

      list.forEach((element: any) => {
        array.push(element.clientHeight);
      });
      setHeightList(array);
    }, 200);
  }, [i18n.language]);

  return (
    <RootLayout>
      <Wrapper>
        <div className="top row-between">
          <div className="topInner row-between">
            <h2 className="title">FAQ</h2>
            <img src={require("@Assets/images/faq/banner.png")} alt="banner" className="right" />
          </div>
        </div>
        <div className="content">
          <div className="inner" ref={refEl}>
            {arr.map((item, index) => (
              <div className="faqItem" key={item.title}>
                <div className="itemTitle row-start" onClick={() => setOpen(open === index ? -1 : index)}>
                  <p className="row-center">
                    <i
                      className={classnames({
                        iconfont: true,
                        iconplus: open !== index,
                        iconminus: open === index,
                      })}
                    />
                  </p>
                  <p>{t(item.title)}</p>
                </div>
                <div className="itemContent" style={{ height: open === index ? `${heightList[index]}px` : 0 }}>
                  <div
                    className="itemInner"
                    dangerouslySetInnerHTML={{
                      __html: t(item.content),
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </Wrapper>
    </RootLayout>
  );
}

export default FAQPage;
