/* eslint-disable operator-linebreak */
import { useEffect, useState, useRef } from "react";
import styled from "styled-px2vw";
import { useTranslation } from "react-i18next";
import Request from "@Utils/request";
import qs from "qs";
import Decimal from "decimal.js";
import { useInterval, useSize } from "ahooks";

import CanvasLine from "@Components/Trend";

const Wrapper = styled.div`
  background: #fff;
  padding: 100px 0 40px;
  .inner {
    padding: 0 50px;
    > .title {
      width: 100%;
      color: #080f2a;
      margin-bottom: 76px;
      & > :first-child {
        font-size: 54px;
        font-family: CASaygonText-Medium, CASaygonText;
        font-weight: 500;
        color: #080f2a;
        line-height: 64px;
      }
      & > :last-child {
        font-size: 24px;
        font-family: CASaygonText-Regular, CASaygonText;
        font-weight: 400;
        color: #080f2a;
        line-height: 32px;
        padding-top: 20px;
      }
    }
    > .content {
      width: 100%;
      > div {
        width: 100%;
        height: 232px;
        padding: 27px 24px 0;
        margin-bottom: 30px;
        background: #ffffff;
        box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        > .info {
          width: 100%;
          > .coin {
            > img {
              width: 44px;
              height: 44px;
              margin-right: 20px;
            }
            > p {
              font-size: 32px;
              font-family: CASaygonText-Bold, CASaygonText;
              font-weight: bold;
              color: #080f2a;
              line-height: 38px;
            }
          }
          > .price {
            font-size: 32px;
            font-family: CASaygonText-Medium, CASaygonText;
            font-weight: 500;
            color: #080f2a;
            line-height: 38px;
          }
        }
        .change {
          width: 100%;
          margin-top: 12px;
          > .usPrice {
            font-size: 32px;
            font-family: CASaygonText-Medium, CASaygonText;
            font-weight: 500;
            color: #00ac8c;
            line-height: 38px;
            > span {
              padding-left: 6px;
            }
          }
        }
      }
    }
  }
`;
// interface IRate {
//   BTC: string;
//   USD: string;
//   USDT: string;
// }
interface IChangesItem {
  s: string;
  m: string;
}
interface ISymbolItem {
  c: string;
  h: string;
  l: string;
  o: string;
  s: string;
  sn: string;
}
interface ISymbol {
  coin: string;
  list: ISymbolItem[];
}
const coinArray = ["BTCMMXN", "ETHMMXN", "USDTMMXN"];

function TradeMmxn() {
  const { t } = useTranslation();
  const ref: any = useRef();
  const size = useSize(ref);
  const [isPad, setIsPad] = useState<boolean>(false);
  const [partShow, setPartShow] = useState<Boolean>(false);
  useEffect(() => {
    const sUserAgent = navigator.userAgent.toLowerCase();
    const isIpad = sUserAgent.indexOf("ipad") > -1;
    setIsPad(isIpad);
  }, [size]);
  // const [rates, setRates] = useState<IRate>({
  //   BTC: "0",
  //   USD: "0",
  //   USDT: "0",
  // });
  const [symbols, setSymbols] = useState<ISymbol[]>([
    {
      coin: "",
      list: [
        {
          c: "0",
          h: "0",
          l: "0",
          o: "0",
          s: "",
          sn: "",
        },
      ],
    },
  ]);
  const [changes, setChanges] = useState<IChangesItem[]>([]);
  const initData = () => {
    // async function getRates() {
    //   const response = await Request({
    //     url: "/quote/v1/rates?tokens=MMXN&legalCoins=BTC,USDT,USD",
    //   });
    //   if (response.data.code === 0) {
    //     setRates(response.data.data[0].rates);
    //   }
    // }
    async function getData() {
      const data: ISymbol[] = [];
      const params = {
        symbol: coinArray.join(","),
        exchangeId: 301,
        interval: "15m",
        limit: 80,
        realtimeInterval: "24h",
      };
      const response = await Request({
        url: `/quote/v1/multi/kline?${qs.stringify(params)}`,
      });
      if (response.data.code === 200) {
        const origin = response.data.data;
        Object.keys(origin).forEach(ele => {
          const object = {
            coin: ele,
            list: origin[ele] || [],
          };
          data.push(object);
        });
        setSymbols(data);
        setPartShow(data.length !== 0);
      }
    }
    async function getChange() {
      const response = await Request({
        url: "/quote/v1/broker/tickers",
      });
      if (response.data.code === 200) {
        const array: IChangesItem[] = [];
        response.data.data.map((item: IChangesItem) => {
          if (coinArray.includes(item.s)) {
            array.push(item);
            if (array.length === coinArray.length) {
              setChanges(array);
            }
          }
        });
      }
    }
    // getRates();
    getData();
    getChange();
  };
  useEffect(() => {
    initData();
  }, []);
  useInterval(() => {
    initData();
  }, 10000);

  return (
    <>
      {partShow && (
        <Wrapper>
          <div className="inner" ref={ref}>
            <div className="title">
              <p>{t("Trade with MMXN")}</p>
              <p>{t("Trade Poplular Cryptocurrencies with MMXN Pairs")}</p>
            </div>
            <div className="content">
              {symbols.length === coinArray.length &&
                coinArray.map((item: string) => {
                  const currentData: ISymbol = symbols.filter((current: ISymbol) => current.coin === item)[0] || {
                    list: [
                      {
                        c: "0",
                        h: "0",
                        l: "0",
                        o: "0",
                        s: "",
                        sn: "",
                      },
                    ],
                    coin: "",
                  };
                  const showData: ISymbolItem = currentData.list[currentData.list.length - 1];
                  if (!showData) return null;
                  // 计算 to usdt
                  // const toUsdt: string = new Decimal(showData.c || "0").mul(rates.USD || "0").toFixed(2, 1);
                  // 币种分割
                  const base: string = showData.s.substring(0, showData.s.indexOf("MMXN"));
                  // 涨跌
                  const currentChange = changes.filter((current: IChangesItem) => current.s === item)[0] || { m: 0 };
                  const { m } = currentChange;
                  // 计算涨幅具体数字
                  const lastPrice = new Decimal(showData.c || "0").div(new Decimal(m || "0").add("1"));
                  const changePrice: string = new Decimal(showData.c).minus(lastPrice).toFixed(base === "USDT" ? 4 : 2, 1);
                  // 区域图涨跌颜色
                  const color = Number(m) >= 0 ? "#00AC8C" : "#F24059";
                  return (
                    <div
                      className="col-start"
                      key={item}
                      onClick={() => {
                        window.open(`https://www.mexo.io/crypto-spot-trading/${base}/MMXN`, "_blank");
                      }}
                    >
                      <div className="info row-between">
                        <div className="coin row-start">
                          <img src={require(`@Assets/images/home/${item}.svg`)} alt={item} />
                          <p>{base} / MMXN</p>
                        </div>
                        <p className="price">{showData.c}</p>
                      </div>
                      <div className="change row-between">
                        <p className="usPrice" style={{ color }}>
                          {Number(changePrice) > 0 ? "+" : ""}
                          {changePrice}
                          &nbsp;
                          {m ? (
                            <span>
                              ( {Number(m) > 0 ? "+" : ""}
                              {Math.floor(Number(m) * 10000) / 100} % )
                            </span>
                          ) : (
                            <span style={{ fontSize: "18px", color: "#f0f7ff" }}>--</span>
                          )}
                        </p>
                        <CanvasLine
                          width={isPad ? 260 : 140}
                          height={isPad ? 120 : 72}
                          borderColor={color}
                          gradientColor={["transparent", "transparent"]}
                          data={currentData.list}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </Wrapper>
      )}
    </>
  );
}

export default TradeMmxn;
