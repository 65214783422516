/* eslint-disable operator-linebreak */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Request from "@Utils/request";
import qs from "qs";
import Decimal from "decimal.js";
import { useInterval } from "ahooks";

import CanvasLine from "@Components/Trend";

const Wrapper = styled.div`
  background: #fff;
  min-width: 100%;
  width: fit-content;
  overflow-x: hidden;
  padding: 100px 120px;
  > .inner {
    /* padding-bottom: 130px; */
    max-width: 1400px;
    min-width: 1200px;
    margin: 0 auto;
    position: relative;
    > .title {
      width: 100%;
      color: #080f2a;
      margin-bottom: 76px;
      & > :first-child {
        font-size: 48px;
        font-weight: 500;
        line-height: 58px;
        font-family: CASaygonText-Medium, CASaygonText;
      }
      & > :last-child {
        font-family: CASaygonText-Medium, CASaygonText;
        font-size: 16px;
        font-weight: 400;
        color: #080f2a;
        line-height: 24px;
        width: 300px;
        word-break: break-word;
      }
    }
    > .content {
      width: 100%;
      > div {
        /* width: 23.75%; */
        width: 30%;
        height: 258px;
        padding: 24px 24px 0;
        cursor: pointer;
        background: #ffffff;
        box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        transition: all 0.2s linear;
        &:hover {
          transform: translateY(-6%);
        }
        > .coin {
          > img {
            width: 30px;
            height: 30px;
            margin-right: 10px;
          }
          > p {
            font-size: 20px;
            font-family: CASaygonText-Bold, CASaygonText;
            font-weight: bold;
            color: #080f2a;
            line-height: 24px;
          }
        }
        > .price {
          font-size: 16px;
          font-family: CASaygonText-Medium, CASaygonText;
          font-weight: 500;
          color: #080f2a;
          padding: 22px 0 10px 0;
        }
        > .usPrice {
          font-size: 16px;
          font-family: CASaygonText-Medium, CASaygonText;
          font-weight: 500;
          color: #00ac8c;
          padding-bottom: 14px;
          > span {
            padding-left: 6px;
          }
        }
      }
    }
  }
`;
const coinArray = ["BTCMMXN", "ETHMMXN", "USDTMMXN"];

// interface IRate {
//   BTC: string;
//   USD: string;
//   USDT: string;
// }
interface IChangesItem {
  s: string;
  m: string;
}
interface ISymbolItem {
  c: string;
  h: string;
  l: string;
  o: string;
  s: string;
  sn: string;
}
interface ISymbol {
  coin: string;
  list: ISymbolItem[];
}

function TradeMmxn() {
  const { t } = useTranslation();
  // const [rates, setRates] = useState<IRate>({
  //   BTC: "0",
  //   USD: "0",
  //   USDT: "0",
  // });
  const [symbols, setSymbols] = useState<ISymbol[]>([
    {
      coin: "",
      list: [
        {
          c: "0",
          h: "0",
          l: "0",
          o: "0",
          s: "",
          sn: "",
        },
      ],
    },
  ]);
  const [changes, setChanges] = useState<IChangesItem[]>([]);
  const [partShow, setPartShow] = useState<Boolean>(false);

  const initData = () => {
    // async function getRates() {
    //   const response = await Request({
    //     url: "/quote/v1/rates?tokens=MMXN&legalCoins=BTC,USDT,USD",
    //   });
    //   if (response.data.code === 0) {
    //     setRates(response.data.data[0].rates);
    //   }
    // }
    async function getData() {
      const data: ISymbol[] = [];
      const params = {
        symbol: coinArray.join(","),
        exchangeId: 301,
        interval: "15m",
        limit: 80,
        realtimeInterval: "24h",
      };
      const response = await Request({
        url: `/quote/v1/multi/kline?${qs.stringify(params)}`,
      });
      if (response.data.code === 200) {
        const origin = response.data.data;
        Object.keys(origin).forEach(ele => {
          const object = {
            coin: ele,
            list: origin[ele] || [],
          };
          data.push(object);
        });
        setSymbols(data);
        setPartShow(data.length !== 0);
      }
    }
    async function getChange() {
      const response = await Request({
        url: "/quote/v1/broker/tickers",
      });
      if (response.data.code === 200) {
        const array: IChangesItem[] = [];
        response.data.data.map((item: IChangesItem) => {
          if (coinArray.includes(item.s)) {
            array.push(item);
            if (array.length === coinArray.length) {
              setChanges(array);
            }
          }
        });
      }
    }
    // getRates();
    getData();
    getChange();
  };
  useEffect(() => {
    initData();
  }, []);
  useInterval(() => {
    initData();
  }, 10_000);
  return (
    <>
      {partShow && (
        <Wrapper>
          <div className="inner">
            <div className="title row-between">
              <p>{t("Trade with MMXN")}</p>
              <p>{t("Trade Poplular Cryptocurrencies with MMXN Pairs")}</p>
            </div>
            <div className="content row-between">
              {symbols.length === coinArray.length &&
                coinArray.map((item: string) => {
                  const currentData: ISymbol = symbols.filter((current: ISymbol) => current.coin === item)[0] || {
                    list: [
                      {
                        c: "0",
                        h: "0",
                        l: "0",
                        o: "0",
                        s: "",
                        sn: "",
                      },
                    ],
                    coin: "",
                  };
                  const showData: ISymbolItem = currentData.list[currentData.list.length - 1]; // 最新成交价
                  if (!showData) return null;
                  // 计算 to usdt
                  // const toUsdt: string = new Decimal(showData.c || "0").mul(rates.USD || "0").toFixed(2, 1);
                  // 币种分割
                  const base: string = showData.s.substring(0, showData.s.indexOf("MMXN"));
                  // 涨跌
                  const currentChange = changes.filter((current: IChangesItem) => current.s === item)[0] || { m: 0 };
                  const { m } = currentChange;
                  // 计算涨幅具体数字
                  const lastPrice = new Decimal(showData.c || "0").div(new Decimal(m || "0").add("1"));
                  const changePrice: string = new Decimal(showData.c || "0").minus(lastPrice).toFixed(base === "USDT" ? 4 : 2, 1);
                  // 区域图涨跌颜色
                  const color = Number(m) >= 0 ? "#00AC8C" : "#F24059";
                  return (
                    <div
                      className="col-start"
                      key={item}
                      onClick={() => {
                        window.open(`https://www.mexo.io/crypto-spot-trading/${base}/MMXN`, "_blank");
                      }}
                    >
                      <div className="coin row-start">
                        <img src={require(`@Assets/images/home/${item}.svg`)} alt={item} />
                        <p>{base} / MMXN</p>
                      </div>
                      <p className="price">{showData.c}</p>
                      <p className="usPrice" style={{ color }}>
                        {Number(changePrice) > 0 ? "+" : ""}
                        {changePrice}
                        &nbsp;
                        {m ? (
                          <span>
                            ( {Number(m) > 0 ? "+" : ""}
                            {Math.floor(Number(m) * 10000) / 100} % )
                          </span>
                        ) : (
                          <span style={{ fontSize: "18px", color: "#f0f7ff" }}>--</span>
                        )}
                      </p>
                      <CanvasLine
                        width={300}
                        height={92}
                        borderColor={color}
                        gradientColor={["transparent", "transparent"]}
                        data={currentData.list}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </Wrapper>
      )}
    </>
  );
}

export default TradeMmxn;
