import { ButtonHTMLAttributes, ReactNode, FC } from "react";
import styled from "styled-px2vw";

const Wrapper = styled.button`
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  /* border: none; */
  border-radius: 0;
  white-space: nowrap;
  -webkit-appearance: none;
  cursor: pointer;
  user-select: none;
  width: 209px;
  height: 108px;
  outline: none !important;
  border: 0.51px solid ${props => (props.theme === "dark" ? "#080f2a" : "#ffffff")};
  background: transparent;
  font-size: 24px;
  font-family: CASaygonText-Regular, CASaygonText;
  font-weight: 400;
  color: ${props => (props.theme === "dark" ? "#080f2a" : "#ffffff")};
  transform: translate(0px, 0px);
  transition: color 0.4s cubic-bezier(0.65, 0, 0.35, 1);
`;

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  theme?: "dark" | "light";
  children: ReactNode;
  onClick?: () => void;
}

const Button: FC<ButtonProps> = (props: ButtonProps) => {
  const { theme, children, onClick, ...otherProps } = props;

  return (
    <Wrapper {...otherProps} className="x-button" theme={theme} onClick={onClick}>
      {children}
    </Wrapper>
  );
};

export default Button;
