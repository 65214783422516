import styled from "styled-px2vw";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  background: #00ec7d;
  > .inner {
    padding: 120px 50px 0;
    > div {
      width: 100%;
      color: #080f2a;
      align-items: flex-start;
      padding-bottom: 100px;
      > :first-child {
        font-size: 32px;
        font-family: CASaygonText-Bold, CASaygonText;
        color: #080f2a;
      }
      > p {
        font-size: 24px;
        font-family: CASaygonText-Regular, CASaygonText;
        font-weight: 400;
        color: #080f2a;
        line-height: 36px;
        padding-top: 18px;
        word-break: break-word;
      }
      > div {
        padding-top: 10px;
        width: 100%;
        font-size: 24px;
        font-family: CASaygonText-Bold, CASaygonText;
        font-weight: bold;
        color: rgba(8, 15, 42, 0.8);
      }
    }
  }
  > .line {
    height: 3px;
    background: rgba(8, 15, 42, 0.5);
    margin: 78px 50px 0;
  }
`;

function Contact() {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <div className="inner col-between">
        <div className="col-between">
          <h5>{t("Business Inqueries")}</h5>
          <p>{t("please reach")}</p>
          <div onClick={() => window.open("mailto:contact@monetadigital.com", "_blank")}>contact@monetadigital.com</div>
        </div>
        <div className="col-between" style={{ paddingBottom: 0 }}>
          <h5>{t("Large Amount Transaction")}</h5>
          <p>{t("please connect")}</p>
          <div onClick={() => window.open("mailto:otc@monetadigital.com", "_blank")}>otc@monetadigital.com</div>
        </div>
      </div>
      <div className="line" />
    </Wrapper>
  );
}

export default Contact;
