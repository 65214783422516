import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  background: #00ec7d;
  width: fit-content;
  min-width: 100%;
  padding: 120px 120px 6px;
  > .inner {
    width: 100%;
    min-width: 1200px;
    max-width: 1400px;
    margin: 0 auto;
    align-items: stretch;
    > div {
      width: 500px;
      color: #080F2A;
      align-items: flex-start;
      >:first-child {
        font-size: 20px;
        font-family: CASaygonText-Bold, CASaygonText;
        color: #080F2A;
      }
      > p {
        font-size: 16px;
        font-family: CASaygonText-Regular, CASaygonText;
        font-weight: 400;
        color: #080F2A;
        line-height: 24px;
        padding-top: 20px;
        word-break: break-word;
        > span {
          color: rgba(8, 15, 42, .85);
          padding-left: 4px;
          cursor: pointer;
          font-family: CASaygonText-Bold, CASaygonText;
          &:hover {
            color: rgba(8, 15, 42, 1);
          }
        }
      }
    }
  }
`;

function Contact() {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <div className="inner row-between">
        <div className="col-start">
          <h5>{t("Business Inqueries")}</h5>
          <p>
            {t("please reach")}
            <span onClick={() => window.open("mailto:contact@monetadigital.com", "_blank")}>
              contact@monetadigital.com
            </span>
          </p>
        </div>
        <div className="col-start">
          <h5>{t("Large Amount Transaction")}</h5>
          <p>
            {t("please connect")}
            <span onClick={() => window.open("mailto:otc@monetadigital.com", "_blank")}>
              otc@monetadigital.com
            </span>
          </p>
        </div>
      </div>
    </Wrapper>
  );
}

export default Contact;
