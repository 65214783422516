import styled from "styled-px2vw";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  background: #F7F7F7;
  padding-top: 48px;
  padding-bottom: 20px;
  > .inner {
    > .line {
    height: 1px;
    width: 96px;
    background: rgba(8, 15, 42, 0.5);
    margin-bottom: 50px;
  }
    padding: 0 50px;
    > .top {
      height: 100%;
      text-align: center;
      >:first-child {
        font-size: 54px;
        font-family: CASaygonText-Medium, CASaygonText;
        font-weight: 500;
        color: #080F2A;
        padding-bottom: 40px;
      }
      >:last-child {
        font-size: 24px;
        font-family: CASaygonText-Regular, CASaygonText;
        font-weight: 400;
        color: #080F2A;
        line-height: 36px;
      }
    }
    > .bottom {
      padding-top: 100px;
      flex-wrap: wrap;
      align-items: flex-start;
      > div {
        margin: 0 0 100px 0;
        width: 280px;
        &:nth-child(2n) {
          margin-right: 0;
        }
        img {
          width: 193px;
          height: 142px;
        }
        p {
          white-space: nowrap;
          margin-top: 30px;
          font-size: 24px;
          font-family: CASaygonText-Medium, CASaygonText;
          font-weight: 500;
          color: #080F2A;
        }
      }
    }
  }
`;

function UseCase() {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <div className="inner col-center">
        <div className="line" />
        <div className="top">
          <p>{t("Use Cases")}</p>
          <p>
            {/* {t("caseText")} */}
          </p>
        </div>
        <div className="bottom row-between">
          <div className="col-center">
            <img src={require("@Assets/images/home/medium.svg")} alt="MMXN" />
            <p>{t("cases1")}</p>
          </div>
          <div className="col-center">
            <img src={require("@Assets/images/home/financial.svg")} alt="MMXN" />
            <p>{t("cases2")}</p>
          </div>
          <div className="col-center">
            <img src={require("@Assets/images/home/remittances.svg")} alt="MMXN" />
            <p>{t("cases3")}</p>
          </div>
          <div className="col-center">
            <img src={require("@Assets/images/home/payments.svg")} alt="MMXN" />
            <p>{t("cases4")}</p>
          </div>
          <div className="col-center">
            <img src={require("@Assets/images/home/store.svg")} alt="MMXN" />
            <p>{t("cases5")}</p>
          </div>
          <div className="col-center">
            <img src={require("@Assets/images/home/dapp.svg")} alt="MMXN" />
            <p>{t("cases6")}</p>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default UseCase;
